import React from  'react';
import { Col, FormLabel, FormControl, FormGroup } from 'react-bootstrap';
import {
    getVoucherTypes, IVoucher, ITaxingAuthority, SelectOptions,
    VoucherProperty, ITaxReturn, VoucherNo, VoucherTypes,
    VoucherMode, EngagementType, CustomType, DocumentGroups, initialVoucher
} from '../../TaxReturn';
import { CustomDatePicker } from '../../controls/CustomDatePicker';
import { VoucherTabConstants, customAuthority } from '../../../../../src/components/helper/Constants';
import { RadioButtonComponent } from '../../RadioButtonComponent';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import { CurrencyTextBox } from '../../../common/controls/CurrencyTextBox';
import moment from "moment";
import { ConvertDateToUtc } from '../../../helper/Validations';
import { AutoSuggestTextbox } from '../../../common/controls/AutoSuggestTextbox';
import { VoucherPayment, VoucherEstimatedPayment } from '../../../svg/IconCollection';
import { filterTaxFilingDates } from 'src/components/helper/VoucherNameFilter';


export interface RegroupToVoucherProps {
    voucher: IVoucher
    authorities: SelectOptions[],
    pageNo: number,
    onVoucherDataUpdate: (propertyType: VoucherProperty, value: any, pageNumber: number) => void,
    allTaxingAuthorities: ITaxingAuthority[];
    voucherStore?: IVoucherState;
    taxReturn?: ITaxReturn;
}

export interface IRegroupToVoucherState {
    currentVoucher: IVoucher;
    isPaymentUrlSame: boolean;
    masterPaymentUrl: string;
    isMasterUrl: boolean;
    paymentFormNames: SelectOptions[];
    estimatedFormNames: SelectOptions[];
}

export class RegroupToVoucher extends React.Component<RegroupToVoucherProps, IRegroupToVoucherState>
{
    constructor(props: RegroupToVoucherProps) {
        super(props);
        this.state = {
            currentVoucher: props && props.voucher ? props.voucher : initialVoucher,
            isPaymentUrlSame: true,
            masterPaymentUrl: "",
            isMasterUrl: false,
            paymentFormNames: [],
            estimatedFormNames: []
        };
    }
    componentWillReceiveProps(nextProps: RegroupToVoucherProps) {
        const tempVoucher: IVoucher = nextProps.voucher;
        if (nextProps && nextProps.voucher) {
            this.setState({
                currentVoucher: tempVoucher
            });
        }
    }

    authorityChange = (event: any) => {
        this.clearPaymentFormNameState();
        const tempVoucher: IVoucher = { ...this.state.currentVoucher };
        tempVoucher.authorityID = parseInt(event.target.value);
        tempVoucher.authorityName = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text;
        if (tempVoucher.authorityID < customAuthority.customAuthorityId) {
            this.setVoucherFormName(tempVoucher);
        }
        this.setPaymentUrl(tempVoucher);
        this.setDueDate(tempVoucher);
        this.setState({ currentVoucher: tempVoucher });
        this.props.onVoucherDataUpdate(VoucherProperty.authorityID, tempVoucher.authorityID, this.props.pageNo);
    }

    paymentType = (event: any) => {
        this.clearPaymentFormNameState();
        let tempVoucher: IVoucher = { ...this.state.currentVoucher };
        tempVoucher.voucherNo = parseInt(event.target.value);
        tempVoucher.paymentType = getVoucherTypes(event.target.value);
        if (tempVoucher.authorityID < customAuthority.customAuthorityId) {
            this.setVoucherFormName(tempVoucher);
        }
        this.setPaymentUrl(tempVoucher);
        this.setDueDate(tempVoucher);
        this.setState({ currentVoucher: tempVoucher });
        this.props.onVoucherDataUpdate(VoucherProperty.voucherTypes, event.target.value, this.props.pageNo);
    }


    private setVoucherDueDate = (voucher: IVoucher) => {
        if (this.props.voucherStore != undefined) {
            const voucherDueDates: any = this.props.voucherStore.voucherDefaultDates
                .filter(x => x.authorityId == voucher.authorityID);
            const voucherDueDate = filterTaxFilingDates(voucherDueDates, voucher.formName, voucher.paymentType)
            if (voucherDueDate) {
                switch (voucher.voucherNo) {
                    case VoucherNo.PaymentVoucher:
                        voucher.dueDate = voucherDueDate.taxFilingDate ? voucherDueDate.taxFilingDate : voucher.dueDate;
                        break;
                    case VoucherNo.Q1:
                        voucher.dueDate = voucherDueDate.q1;
                        break;
                    case VoucherNo.Q2:
                        voucher.dueDate = voucherDueDate.q2;
                        break;
                    case VoucherNo.Q3:
                        voucher.dueDate = voucherDueDate.q3;
                        break;
                    case VoucherNo.Q4:
                        voucher.dueDate = voucherDueDate.q4;
                        break;
                }
            }
        }
    }

    dueDateChange = (event: any) => {
        var date = moment(event, 'MM/DD/YYYY').toDate();
        //Please don't simple convert this to UTC again with out knowing the issues.
        //Converting UTC will change the time by one day in uS time zone.
        //We will feel like it working fine indian time zone 
        this.setState({ currentVoucher: { ...this.state.currentVoucher, dueDate: date } });
        this.props.onVoucherDataUpdate(VoucherProperty.dueDate, date, this.props.pageNo);
    }

    amountBlur = (value: number) => {
        this.setState({ currentVoucher: { ...this.state.currentVoucher, amount: value } });
        this.props.onVoucherDataUpdate(VoucherProperty.amount, value, this.props.pageNo);
    }
    formNameChange = (event: any) => {
        this.setState({ currentVoucher: { ...this.state.currentVoucher, formName: event.target.value } });
        this.props.onVoucherDataUpdate(VoucherProperty.formName, event.target.value, this.props.pageNo);
    }

    private onCustomTextChange = (event: any) => {
        this.setState({ currentVoucher: { ...this.state.currentVoucher, customText: event.target.value } });
        this.props.onVoucherDataUpdate(VoucherProperty.customText, event.target.value, this.props.pageNo);
    }
    private onPaymentUrlChange = (event: any) => {
        let tempVoucher: IVoucher = { ...this.state.currentVoucher };
        tempVoucher.onlinePaymentUri = event.target.value;
        this.setState({ currentVoucher: tempVoucher });
        this.props.onVoucherDataUpdate(VoucherProperty.onlinePaymentUri, event.target.value, this.props.pageNo);
    }

    private onPaymentUrlChangeRadio = (event: any) => {
        let tempVoucher: IVoucher = { ...this.state.currentVoucher };
        if (event.target.value == 1) {
            this.setState({
                isMasterUrl: true

            });
            this.props.onVoucherDataUpdate(VoucherProperty.isMasterPaymentUrl, true, this.props.pageNo);
            this.props.onVoucherDataUpdate(VoucherProperty.onlinePaymentUri, this.state.masterPaymentUrl, this.props.pageNo);
        }
        else {
            this.setState({
                isMasterUrl: false
            });
            this.props.onVoucherDataUpdate(VoucherProperty.isMasterPaymentUrl, false, this.props.pageNo);
            this.props.onVoucherDataUpdate(VoucherProperty.onlinePaymentUri, tempVoucher.onlinePaymentUri, this.props.pageNo);
        }

    }

    private onPaymentMethodChange = (event: any) => {
        let tempVoucher: IVoucher = { ...this.state.currentVoucher };
        tempVoucher.customType = parseInt(event.target.value);
        this.setPaymentUrl(tempVoucher);
        this.setState({ currentVoucher: tempVoucher });
        this.props.onVoucherDataUpdate(VoucherProperty.customType, parseInt(event.target.value), this.props.pageNo);
    }

    private setPaymentUrl(tempVoucher: IVoucher) {
        let editedPaymentUrl: string = '';
        let customPaymentUrl: string = '';
        let masterPaymentUrl: string = '';
        let isPaymentUrlSame: boolean = true;
        let isMasterUrl: boolean = true;
        const taxingAuthority: any = this.props.allTaxingAuthorities.find(x => x.Id == tempVoucher.authorityID);
        if (tempVoucher.customType === CustomType.OnlinePaymentRequired && tempVoucher.voucherNo > 0 && taxingAuthority != undefined) {
            editedPaymentUrl = this.getPaymentUrl(tempVoucher);
            masterPaymentUrl = this.getMasterPaymentUrl(tempVoucher);
            customPaymentUrl = taxingAuthority.PaymentURL;
            if (taxingAuthority.CustomAuthority) {
                isPaymentUrlSame = true;
                tempVoucher.onlinePaymentUri = customPaymentUrl;
                isMasterUrl = false;
            }
            else if (masterPaymentUrl === null || masterPaymentUrl === '') {
                tempVoucher.onlinePaymentUri = editedPaymentUrl;
                isPaymentUrlSame = true;
                isMasterUrl = false;
            }
            else if (editedPaymentUrl != null && editedPaymentUrl != '') { 
                isPaymentUrlSame = editedPaymentUrl === masterPaymentUrl;
                isMasterUrl = !isPaymentUrlSame;
                tempVoucher.onlinePaymentUri = editedPaymentUrl;
            }
            else {
                tempVoucher.onlinePaymentUri = masterPaymentUrl;
                isPaymentUrlSame = true;
                isMasterUrl = false;
            }

            this.setState({
                isPaymentUrlSame: isPaymentUrlSame,
                masterPaymentUrl: masterPaymentUrl,
                isMasterUrl: isMasterUrl
            });
            this.props.onVoucherDataUpdate(VoucherProperty.isMasterPaymentUrl, isMasterUrl, this.props.pageNo);
            isMasterUrl ? this.props.onVoucherDataUpdate(VoucherProperty.onlinePaymentUri, masterPaymentUrl, this.props.pageNo) :
                this.props.onVoucherDataUpdate(VoucherProperty.onlinePaymentUri, tempVoucher.onlinePaymentUri, this.props.pageNo);
        }
    }

    private getPaymentUrl = (voucher: IVoucher): string => {
        let paymentUrl: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID &&
            voucher.customType === CustomType.OnlinePaymentRequired) {
            if (this.props.voucherStore != undefined && this.props.voucherStore.paymentUrls.length > 0) {
                const paymentUrlInfo: any = this.props.voucherStore.paymentUrls
                    .find(x => x.authorityId === voucher.authorityID
                        && x.engagementType === Number(EngagementType[this.props.taxReturn!.engagementType])
                        && x.voucherType === voucher.paymentType
                    );
                if (paymentUrlInfo) {
                    paymentUrl = paymentUrlInfo.onlinePaymentUrl;
                }
            }
        }
        return paymentUrl;
    }

    private getMasterPaymentUrl = (voucher: IVoucher): string => {
        let paymentUrl: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID &&
            voucher.customType === CustomType.OnlinePaymentRequired) {
            if (this.props.voucherStore != undefined && this.props.taxReturn != undefined) {
                const voucherDefaultDates: any = this.props.voucherStore.voucherDefaultDates
                    .filter(x => x.authorityId === voucher.authorityID &&
                        x.taxYear === this.props.taxReturn!.taxYear &&
                        x.returnType === Number(EngagementType[this.props.taxReturn!.engagementType]));
                const paymentUrlInfo: any = filterTaxFilingDates(voucherDefaultDates, voucher.formName, voucher.paymentType)
                if (paymentUrlInfo) {
                    switch (voucher.voucherNo) {
                        case VoucherNo.PaymentVoucher:
                            paymentUrl = paymentUrlInfo.paymentURL;
                            break;
                        case VoucherNo.Q1:
                        case VoucherNo.Q2:
                        case VoucherNo.Q3:
                        case VoucherNo.Q4:
                            paymentUrl = paymentUrlInfo.esPaymentURL;
                            break;
                        default:
                            paymentUrl = '';
                            break;
                    }
                }
            }
        }
        return paymentUrl;
    }

    private setDueDate = (voucher: IVoucher) => {
        var dueDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        voucher.dueDate = ConvertDateToUtc(dueDate);
        if (voucher.authorityID !== initialVoucher.authorityID
            && voucher.voucherNo !== initialVoucher.voucherNo) {
            this.setVoucherDueDate(voucher);
            this.props.onVoucherDataUpdate(VoucherProperty.dueDate, voucher.dueDate, this.props.pageNo);
        }
    }

    private onFormNameChangeDropdown = (value: any) => {
        let tempVoucher: IVoucher = { ...this.state.currentVoucher };
        const paymentType: VoucherTypes = tempVoucher.voucherNo > 1 ? VoucherTypes.EstimatedVoucher : VoucherTypes.PaymentVoucher;
        if (this.props.taxReturn) {
            let voucherIndex = this.props.taxReturn.formGroups
                .findIndex(x => x.group == DocumentGroups.Vouchers);
            if (voucherIndex > 0) {
                let voucherForms: IVoucher[] = this.props.taxReturn.formGroups[voucherIndex].forms as IVoucher[];
                voucherForms.filter(x => x.authorityID === tempVoucher.authorityID && x.paymentType === paymentType).map((voucher, i) => {
                    voucher.isFormNameUpdated = false
                });
            }
        }
        tempVoucher.formName = value;
        tempVoucher.isFormNameUpdated = true;
        this.setState({ currentVoucher: tempVoucher });
        this.props.onVoucherDataUpdate(VoucherProperty.isFormNameUpdated, true, this.props.pageNo);
        this.props.onVoucherDataUpdate(VoucherProperty.formName, value, this.props.pageNo);
    };

    private getMasterFormName = (voucher: IVoucher): string => {
        let formName: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID) {
            if (this.props.voucherStore != undefined && this.props.taxReturn != undefined) {
                const masterFormName: any = this.props.voucherStore.voucherDefaultDates
                    .find(x => x.authorityId === voucher.authorityID &&
                        x.taxYear === this.props.taxReturn!.taxYear &&
                        x.returnType === Number(EngagementType[this.props.taxReturn!.engagementType]));
                if (masterFormName) {
                    switch (voucher.voucherNo) {
                        case VoucherNo.PaymentVoucher:
                            formName = masterFormName.voucherName ? masterFormName.voucherName : "";
                            break;
                        case VoucherNo.Q1:
                        case VoucherNo.Q2:
                        case VoucherNo.Q3:
                        case VoucherNo.Q4:
                            formName = masterFormName.esVoucherName ? masterFormName.esVoucherName : "";
                            break;
                        default:
                            formName = '';
                            break;
                    }
                }
            }
        }
        return formName;
    }

    private getTenantFormName = (voucher: IVoucher): string => {
        let formName: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID) {
            if (this.props.voucherStore != undefined && this.props.voucherStore.voucherFormNames.length > 0) {
                const tenantFormName: any = this.props.voucherStore.voucherFormNames
                    .find(x => x.authorityId === voucher.authorityID
                        && x.engagementType === Number(EngagementType[this.props.taxReturn!.engagementType])
                        && x.voucherType === voucher.paymentType
                    );
                if (tenantFormName) {
                    formName = tenantFormName.formName;
                }
            }
        }
        return formName;
    }

    private setFormNameDropdownValues(type: VoucherTypes, masterFormName: string, voucherFormName: string) {
        let ddIndex: number = 0;
        if (masterFormName) {
            this.manageFormNameState(type, ddIndex, masterFormName);
            ddIndex++;
        }
        if (voucherFormName) {
            this.manageFormNameState(type, ddIndex, voucherFormName);
            ddIndex++;
        }
    }

    private manageFormNameState = (type: VoucherTypes, ddIndex: number, formName: string) => {
        if (type === VoucherTypes.PaymentVoucher && this.state.paymentFormNames.findIndex(x => x.label === formName) < 0) {
            this.state.paymentFormNames.push({ value: ddIndex.toString(), label: formName })
        }
        else {
            if (type === VoucherTypes.EstimatedVoucher && this.state.estimatedFormNames.findIndex(x => x.label === formName) < 0) {
                this.state.estimatedFormNames.push({ value: ddIndex.toString(), label: formName });
            }
        }
    }

    private clearPaymentFormNameState = () => {
        this.state.paymentFormNames.length = 0;
        this.state.estimatedFormNames.length = 0;
    }

    private setVoucherFormName(tempVoucher: IVoucher) {
        let voucherFormName: string = '';
        let masterFormName: string = '';
        if (tempVoucher.voucherNo > 0) {
            voucherFormName = this.getTenantFormName(tempVoucher);
            masterFormName = this.getMasterFormName(tempVoucher);
            tempVoucher.formName = (voucherFormName !== null && voucherFormName !== undefined && voucherFormName !== '') ? voucherFormName : masterFormName;
            this.setFormNameDropdownValues(tempVoucher.paymentType, masterFormName, voucherFormName);
            this.props.onVoucherDataUpdate(VoucherProperty.formName, tempVoucher.formName, this.props.pageNo);
        }
    }
    render() {
        return (
            <div className="panel-body">
                <FormGroup className="row">
                    <Col className="text-left form-label" sm={3} as={FormLabel}>
                        Taxing Authority
                        </Col>
                    <Col sm={8}>
                        <select className="form-control"
                            name="VoucherRefundTaxingAuthority"
                            value={this.state.currentVoucher.authorityID}
                            onChange={this.authorityChange}>
                            <option value={0} data-abbreviation=''>Select</option>
                            {this.props.authorities.map((method, index) => {
                                return <option key={index} value={method.value}>{method.label}</option>
                            })}
                        </select>

                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Col className="text-left form-label" sm={3} as={FormLabel}>
                        Payment Type
                        </Col>
                    <Col sm={8}>
                        <select className="form-control"
                            name="form-field-voucher-payment-type"
                            value={this.state.currentVoucher.voucherNo}
                            onChange={this.paymentType}>
                            {VoucherTabConstants.AddVoucher.voucherNumbers.map((voucherNo, index) => {
                                return <option key={index} value={voucherNo.value}>{voucherNo.label}</option>
                            })}
                        </select>

                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Col className="text-left form-label" sm={3} as={FormLabel}>
                        Due Date
                        </Col>
                    <Col sm={8}>
                        {
                            <CustomDatePicker value={this.state.currentVoucher.dueDate || new Date()}
                                onChange={this.dueDateChange} />
                        }
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Col className="text-left form-label" sm={3} as={FormLabel}>
                        Amount
                        </Col>
                    <Col sm={8}>
                        <CurrencyTextBox
                            value={this.state.currentVoucher.amount}
                            onBlur={this.amountBlur}
                            decimalPlaces={0} />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Col className="text-left form-label" sm={3} as={FormLabel}>
                        Form Name
                        </Col>
                    <Col sm={8}>
                        <AutoSuggestTextbox id={"voucherFormName"}
                            options={this.state.currentVoucher.paymentType == VoucherTypes.PaymentVoucher ? this.state.paymentFormNames : this.state.estimatedFormNames}
                            onChange={(event) => this.onFormNameChangeDropdown(event)}
                            selectedValue={this.state.currentVoucher.formName}
                            formMenuClass="formNameList"
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <select className="form-control"
                            name="dd-payment-methods"
                            value={this.state.currentVoucher.customType}
                            onChange={this.onPaymentMethodChange}>
                            {VoucherTabConstants.AddVoucher.paymentMethods.map((method, index) => {
                                return <option key={index} value={method.value}>{method.label}</option>
                            })}
                        </select>
                    </Col>
                </FormGroup>

                {this.state.currentVoucher.customType == CustomType.AddCustom ?
                    <FormGroup className="row">
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <textarea style={{ height: '100px' }} className="form-control" name="customText" maxLength={50}
                                onChange={this.onCustomTextChange} value={this.state.currentVoucher.customText} />
                        </Col>
                    </FormGroup> : null}

                {this.state.currentVoucher.customType == CustomType.OnlinePaymentRequired ? this.state.isPaymentUrlSame ?
                    <FormGroup className="row">
                        <Col className="text-left form-label" xs={6} sm={4} md={3} lg={3} as={FormLabel}>
                            {VoucherTabConstants.AddVoucher.PaymentUrl}
                        </Col>
                        <Col xs={6} sm={8} md={9} lg={9}>
                            <FormControl type="url" value={this.state.currentVoucher.onlinePaymentUri} name="paymentUrl"
                                onChange={this.onPaymentUrlChange} />
                        </Col>
                    </FormGroup> :
                    <div>
                        <FormGroup className="row">
                            <Col xs={6} sm={4} md={3} lg={3}>
                                <RadioButtonComponent text=""
                                    id={`addRdbMasterPaymentUrl${this.props.voucher.pageNo}`}
                                    checked={this.state.isMasterUrl}
                                    value={1}
                                    onChange={this.onPaymentUrlChangeRadio}
                                />
                            </Col>
                            <Col xs={6} sm={8} md={9} lg={9}>
                                <span className="span-control overflowText txt-al" title={this.state.masterPaymentUrl}>  {this.state.masterPaymentUrl}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col xs={6} sm={4} md={3} lg={3}>
                                <RadioButtonComponent text=""
                                    id={`addRdbEditedPaymentUrl${this.props.voucher.pageNo}`}
                                    checked={!this.state.isMasterUrl}
                                    value={2}
                                    onChange={this.onPaymentUrlChangeRadio}
                                />
                            </Col>
                            <Col xs={6} sm={8} md={9} lg={9}>
                                {this.state.isMasterUrl ?
                                    <span className="span-control overflowText txt-al" title={this.state.currentVoucher.onlinePaymentUri}> {this.state.currentVoucher.onlinePaymentUri} </span> :
                                    <FormControl type="url" value={this.state.currentVoucher.onlinePaymentUri} name="editedPaymentUrl" onChange={this.onPaymentUrlChange} />}
                            </Col>
                        </FormGroup>
                    </div> : null}
            </div>
        )
    }


}