let moment = require('moment');
import * as bootbox from 'bootbox';
import React from 'react';
import * as ReactDom from 'react-dom';
import {
    BootstrapTable,
    TableHeaderColumn,
    TextFilter,
    CustomFilter,
    SelectFilter,
    DateFilter,
    FilterComparator,
    SelectFilterOptionsType,
    CustomSelectProps,
    ToolBarProps,
    CustomFilterParameters,
} from 'react-bootstrap-table';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { Alert, ButtonToolbar, DropdownButton } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import * as DeliveredReturnsState from '../../../store/reports/DeliveredReturnsState';
import { CheckBoxComponent, CheckBoxSize } from '../../common/CheckBoxComponent';
import {
    ClientTypes,
    DaysRangeOptionsList,
    engagementType,
    getClientName,
    IOfficeLocation,
    ITaxReturn,
    SignatureStatus,
} from '../../common/TaxReturn';
import { DeliveredReturnsConstants, DeliveredReturnsTableConstants } from '../../helper/Constants';
import * as Helper from '../../helper/HelperFunctions';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { CustomDateFilter } from '../DeliveredReturns/CustomDateFilter';
import { DeliveredReportAction } from '../DeliveredReturns/DeliveredReportAction';
import { Filters, IFilters, ReportFilterType } from '../Filters';
import { GlobalSearchField } from '../GlobalSearchField';
import { CustomMultiSelect } from '../../common/MultipleSelectComponent';
import { ColumnSettings } from './../ColumnSettings/ColumnSettings';
import { IColumnSettings, ReportType } from './../../../store/reports/ColumnSettings/ColumnSettingsState';
import { logClientEvent } from '../../helper/LoggerHelper';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import { LogEventConstants } from 'src/components/helper/LogEventConstants';
import { EngagementList } from 'src/Core/Common/Common';
import LinkIcon from '../../../assets/images/LinkIcon';
import { IUTESetting } from 'src/store/common/OnehubStore';

interface TableData {
    pageId: string;
    delivered: DeliveredReturnsState.DeliveredReturnsState;
    saveFilterShow: boolean;
    onPageChange: any;
    onSortChange: any;
    onSearchChange: any;
    onFilterChange: any;
    pageNo: number;
    totalRows: number;
    customColumnHeader: string;
    onExportToExcel(onExportToExcelComplete: () => void): void;
    onFilterNameChange(event: any): void;
    loadGrid(): void;

    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onFilterUpdate(filterName: string): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    filterList: IFilters[];
    currentFilter: IFilters;
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    defaultFilter: string | undefined;
    onSaveFilterShow(): void;
    onSaveFilterHide(): void;

    onEditClientInfoOpen: (rowIndex: number) => void;
    onDownloadEfileFormsOpen: (rowIndex: number) => void;
    onDownloadReturnOpen: (rowIndex: number) => void;
    onResendAccessLinkOpen: (rowIndex: number) => void;
    onClientTrackingOpen: (rowIndex: number) => void;
    onTaxpayerView: (rowIndex: number) => void;
    onUnlockReturnOpen: (rowIndex: number) => void;
    onRecallReturnOpen: (rowIndex: number) => void;
    onVoucherReminderOpen: (rowIndex: number) => void;
    onReportProblemOpen: (rowIndex: number) => void;
    onDeleteTaxReturnOpen: (rowIndex: number) => void;
    onViewAccessCodeOpen: (rowIndex: number) => void;
    onRetentionPolicyOpen: (rowIndex: number) => void;
    onRemoveReport: (event: any) => void;
    onSignedDetailsOpen: (rowIndex: number) => void;
    onDownloadHistoryOpen: (rowIndex: number) => void;
    onUploadFormsOpen: (rowIndex: number) => void;
    onRestoreReturnOpen: (rowIndex: number) => void;
    isArchived: boolean;
    isLoading: boolean;
    pageSize: number;
    onScreenShareViewClick: (rowIndex: number) => void;
    onScreenShareOpen: (clientId: string, rowIndex: number) => void;
    showScreenShareSubMenu: boolean;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    onClientViewClick: (rowIndex: number) => void;
    onClientViewLinkClick: (rowIndex: number, clientType: ClientTypes) => void;
    taxReturn: ITaxReturn;
    showBulkSelectionMessage: boolean;
    onBulkSelectionChange: (isEnabled: boolean) => void;
    clearSelection: () => void;
    proxyFilter: string;
    filterType: ReportFilterType;
    clientInfo: ITaxReturn | undefined;
    onPageReload: () => void;
    onClearFilter: () => void;
}
interface DeliveredReturnsTableProps extends TableData {
    columnSettings: IColumnSettings[];
    saveColumnSettings: (reportType: ReportType, data: IColumnSettings[], callback?: () => void) => void;
    refreshDelay?: boolean;
    loadSpinner?: boolean;
    userLocation?: IOfficeLocation[];
    getLinkedMergeDetails: (
        documentEmail: string,
        callback: (linkedMergedDetailsResponse: DeliveredReturnsState.ILinkedMergedDetailsResponse) => void
    ) => void;
    uteSetting: IUTESetting;
}
export interface IShowExportExcelState {
    isHiddenExportExcel: boolean;
    isActive: boolean;
}
interface DeliveredReturnsTableState extends IShowExportExcelState {
    customColumnOptions: IColumnSettings[];
    tempCustomColumns: IColumnSettings[];
    modifiedCustomColumns: IColumnSettings[];
    showColumnSettingsLoader: boolean;
    showColumnSettings: boolean;
    linkedDetails: string;
}
const isEqual = require('react-fast-compare');

export const SignatureStatusList = {
    None: 'None',
    ESigned: 'E-Signed',
    ManuallySigned: 'Manually Signed',
    Uploaded: 'Uploaded',
    AwaitingESign: 'Awaiting E-Sign',
    AwaitingUpload: 'Awaiting Upload',
    Locked: 'Locked',
    MailOrFax: 'Mail Or Fax',
    SignedAndESigned: 'Signed And E-Signed',
    AutoParsed: 'Auto-Parsed',
    Processing: 'Processing',
    Delivering: 'Delivering',
    Delivered: 'Delivered',
    DeliveryFailed: 'Delivery Failed',
    UploadInProgress: 'Upload In Progress',
    DeliveredToTaxCaddy: 'Delivered To TaxCaddy',
    AlternateDelivery: 'Alternate Delivery',
    AlternateAndUploaded: 'Alternate And Uploaded',
    Reviewed: 'Reviewed',
    AwaitingReview: 'Awaiting Review',
    PartiallySigned: 'Partially Signed',
    PartiallyReviewed: 'Partially Reviewed',
};

export class DeliveredReturnsTable extends React.Component<DeliveredReturnsTableProps, DeliveredReturnsTableState> {
    private filterChanged: boolean = false;
    private taxYearList: any[] = [];
    public refClearSearchBtn: any;
    private customLastReminderFilter: any;
    private customExpireDateFilter: any;
    private customStatusMultiSelect: any;
    private customTaxYearMultiSelect: any;
    public isAppliedFilter: boolean = false;
    private pageTitle: string = this.props.isArchived ? 'ArchivedReport' : 'DeliveredReport';
    private customOfficeMultiSelect: any;

    public headerRefStore: any = {
        taxDocumentName: null,
        clientId: null,
        engagementType: null,
        taxYear: null,
        partner: null,
        deliveredBy: null,
        deliveredDate: null,
        status: null,
        lastReminder: null,
        testColumn: null,
        downloaded: null,
        retentionPeriod: null,
        button: null,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            isActive: true,
            isHiddenExportExcel: false,
            customColumnOptions: [],
            showColumnSettingsLoader: false,
            tempCustomColumns: [],
            modifiedCustomColumns: [],
            showColumnSettings: false,
            linkedDetails: '',
        };
        this.onClearFilter = this.onClearFilter.bind(this);
        this.defaultType = this.defaultType.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onLoadSelectedFilter = this.onLoadSelectedFilter.bind(this);
        this.onFilterSaveClick = this.onFilterSaveClick.bind(this);
        this.ReminderDateFilter = this.ReminderDateFilter.bind(this);
        this.RetentionDateFilter = this.RetentionDateFilter.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.taxYearList = Helper.PrepareTaxYear();
    }

    UNSAFE_componentWillReceiveProps(props: DeliveredReturnsTableProps) {
        this.setState({
            customColumnOptions: props.columnSettings,
            tempCustomColumns: props.columnSettings,
            modifiedCustomColumns: props.columnSettings,
        });
    }

    shouldComponentUpdate(nextProps: DeliveredReturnsTableProps, nextState: IShowExportExcelState) {
        return (
            !isEqual(
                this.props.delivered.deliveredReturnTableModel.documents,
                nextProps.delivered.deliveredReturnTableModel.documents
            ) ||
            this.props.isLoading !== nextProps.isLoading ||
            this.props.saveFilterShow !== nextProps.saveFilterShow ||
            !isEqual(this.state, nextState) ||
            !isEqual(this.props.selectedRows, nextProps.selectedRows) ||
            !isEqual(this.props.showBulkSelectionMessage, nextProps.showBulkSelectionMessage) ||
            !isEqual(this.props.defaultFilter, nextProps.defaultFilter) ||
            !isEqual(this.props.currentFilter, nextProps.currentFilter) ||
            this.props.filterList.length !== nextProps.filterList.length ||
            this.props.refreshDelay !== nextProps.refreshDelay ||
            this.props.clientInfo !== nextProps.clientInfo
        );
    }

    renderShowsTotal(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private setNoContent() {
        if (this.props.isLoading) {
            return (
                <LoadingOverlay style={{ height: '400px' }}>
                    <Loader loading={this.props.isLoading} />
                </LoadingOverlay>
            );
        } else {
            return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound;
        }
    }

    private defaultType(cell: any, row: any) {
        return (
            <div title={cell} className="ellipsis">
                {cell}
            </div>
        );
    }

    getLinkedDetails = (email: string) => {
        this.setState({ linkedDetails: 'Loading...' });
        this.props.getLinkedMergeDetails(
            email,
            (linkedMergedDetailsResponse: DeliveredReturnsState.ILinkedMergedDetailsResponse) => {
                const { linkedDetails, error } = linkedMergedDetailsResponse;
                if (error) {
                    this.setState({ linkedDetails: 'Error while fetching linked report' });
                } else {
                    if (linkedDetails.length === 0) {
                        this.setState({ linkedDetails: 'No Data' });
                    } else {
                        let outputData = '';
                        const uniqueClientIds = linkedDetails.reduce(
                            (clientIds: string[], eachDetail: DeliveredReturnsState.ILinkedMergedDetails) => {
                                if (clientIds.includes(eachDetail.clientId)) {
                                    return clientIds;
                                }
                                clientIds.push(eachDetail.clientId);
                                return clientIds;
                            },
                            []
                        );
                        uniqueClientIds.forEach((eachClientId: string) => {
                            const linkedClientDetails = linkedDetails.filter(
                                (eachDetail: DeliveredReturnsState.ILinkedMergedDetails) => {
                                    return eachDetail.clientId === eachClientId;
                                }
                            );
                            const uniqueClientNames = linkedClientDetails.reduce(
                                (
                                    clientNames: string[],
                                    eachClientDetail: DeliveredReturnsState.ILinkedMergedDetails
                                ) => {
                                    if (clientNames.includes(eachClientDetail.name)) {
                                        return clientNames;
                                    }
                                    clientNames.push(eachClientDetail.name);
                                    return clientNames;
                                },
                                []
                            );
                            uniqueClientNames.forEach((eachClientName: string) => {
                                const linkedDataForClientIdAndName = linkedClientDetails.filter(
                                    (eachClientDetail: DeliveredReturnsState.ILinkedMergedDetails) => {
                                        return (
                                            eachClientDetail.clientId === eachClientId &&
                                            eachClientDetail.name === eachClientName
                                        );
                                    }
                                );
                                outputData += `
                                <div class='linkClientName'>${eachClientName}</div>
                                <div class='linkClientId'>${eachClientId}</div>
                            `;
                                if (linkedDataForClientIdAndName.length === 1) {
                                    outputData += `
                                    <div class='linkEmailId ${
                                        linkedDataForClientIdAndName[0].isPrimaryUTE ? 'linkPrimaryUTE' : ''
                                    }'><span class='emailId'>${linkedDataForClientIdAndName[0].emailId}${
                                        linkedDataForClientIdAndName[0].isSpouse ? ' (Spouse)' : ''
                                    }</span></div>
                                `;
                                } else {
                                    linkedDataForClientIdAndName.forEach(
                                        (
                                            eachLinkedDetail: DeliveredReturnsState.ILinkedMergedDetails,
                                            index: number
                                        ) => {
                                            outputData += `
                                    <div class='linkEmailId ${eachLinkedDetail.isPrimaryUTE ? 'linkPrimaryUTE' : ''}'>${
                                                index + 1
                                            }. <span class='emailId'>${eachLinkedDetail.emailId}${
                                                eachLinkedDetail.isSpouse ? ' (Spouse)' : ''
                                            }</span></div>
                                `;
                                        }
                                    );
                                }
                            });
                        });
                        this.setState({ linkedDetails: outputData });
                    }
                }
            }
        );
    };

    nameType = (cell: any, row: any) => {
        return (
            <div className="nameCell">
                <div title={cell} className="ellipsis">
                    {cell}
                </div>
                {row.isUTELinked && this.props.uteSetting?.uteEnabled && (
                    <ButtonToolbar className="btn-group-vertical linkIconContainer">
                        <DropdownButton
                            size="sm"
                            title={<LinkIcon />}
                            id="linkIcon"
                            data-test-auto="271576c6-b729-4e50-b51a-480831e76dc4"
                            className=""
                            onClick={e => {
                                this.getLinkedDetails(row.taxpayerEmail);
                            }}
                        >
                            <div className="linkedDetailsContainer">
                                <div className="connectedStyle">Connected with:</div>
                                <div className="linkedData">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.linkedDetails }} />
                                </div>
                            </div>
                        </DropdownButton>
                    </ButtonToolbar>
                )}
            </div>
        );
    };

    customTypeTooltip = (cell: any, row: any) => {
        return `${row.engagementType}`;
    };

    customStatusTooltip = (cell: any, row: any) => {
        const status:
            | 'None'
            | 'ESigned'
            | 'ManuallySigned'
            | 'Uploaded'
            | 'AwaitingESign'
            | 'AwaitingReview'
            | 'AwaitingUpload'
            | 'Locked'
            | 'MailOrFax'
            | 'SignedAndESigned'
            | 'AutoParsed'
            | 'Processing'
            | 'Delivering'
            | 'Delivered'
            | 'DeliveryFailed'
            | 'Reviewed'
            | 'PartiallySigned'
            | 'PartiallyReview' =
            typeof row.signatureStatusIdType === 'number'
                ? SignatureStatus[row.signatureStatusIdType]
                : row.signatureStatusIdType;

        return `${SignatureStatusList[status]}`;
    };

    customDownloadedTooltip = (cell: any, row: any) => {
        return `${
            cell > 0 &&
            (row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESigned] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.Uploaded] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.SignedAndESigned])
                ? 'Downloaded'
                : row.bulkDownloadCount > 0
                ? 'Downloaded'
                : 'To Be Downloaded'
        }`;
    };

    private onFilterChange(dataField: any) {
        if (!this.filterChanged && !this.isAppliedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField, this.props.filterType);
            this.filterChanged = false;
        }
    }
    public onClearFilter() {
        this.filterChanged = true;
        for (var key of Object.keys(this.headerRefStore)) {
            this.headerRefStore[key] !== null ? this.headerRefStore[key]?.cleanFiltered() : '';
        }
        this.customStatusMultiSelect.cleanFiltered();
        this.filterChanged = false;
        this.customTaxYearMultiSelect.cleanFiltered();
        this.customOfficeMultiSelect?.cleanFiltered();
        this.props.clearSelection();
        this.props.onClearFilter();

        logClientEvent(`${LogEventConstants.Common.Filter.ClearFilter}  ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
        });
    }

    private onFilterSaveClick(filterName: string) {
        if (!Helper.validateFilter(this.props.currentFilter)) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterFieldsAreEmpty, null);
            return;
        }

        let temThis = this;
        if (typeof filterName == 'undefined' || filterName.length == 0 || filterName == this.props.proxyFilter) {
            this.props.onSaveFilterShow();
        } else {
            bootbox.dialog({
                title: DeliveredReturnsTableConstants.Title.FilterSaveUpdate,
                message: DeliveredReturnsTableConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label:
                            '<i class="fas fa-times"></i>' +
                            DeliveredReturnsTableConstants.ControlLabel.NoUpdateTheCurrent,
                        className: 'btn-white',
                        callback: function () {
                            temThis.props.onFilterUpdate(filterName);
                        },
                    },
                    confirm: {
                        label:
                            '<i class="fas fa-check"></i>' + DeliveredReturnsTableConstants.ControlLabel.YesCreateNew,
                        className: 'btn-info',
                        callback: function () {
                            temThis.props.onSaveFilterShow();
                        },
                    },
                },
            });
        }
    }

    public onLoadSelectedFilter(filter?: IFilters) {
        this.isAppliedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;

            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'taxDocumentName':
                        this.headerRefStore['taxDocumentName']?.applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        this.headerRefStore['clientId']?.applyFilter(filter.fields[field]);
                        break;
                    case 'partner':
                        this.headerRefStore['partner']?.applyFilter(filter.fields[field]);
                        break;
                    case 'deliveredBy':
                        this.headerRefStore['deliveredBy']?.applyFilter(filter.fields[field]);
                        break;
                    case 'status':
                        this.customStatusMultiSelect.applyFilter(filter.fields[field]);
                        break;
                    case 'locationName':
                        this.customOfficeMultiSelect?.applyFilter(filter.fields[field]);
                        break;
                    case 'deliveredDate':
                        let deliveredDateFilter: any = filter.fields[field];
                        if (deliveredDateFilter) {
                            this.headerRefStore['deliveredDate']?.applyFilter(filter.fields[field]);
                        }
                        break;
                    case 'lastReminder':
                        this.customLastReminderFilter?.applyFilter(filter.fields[field]);
                        break;
                    case 'engagementType':
                        this.headerRefStore['engagementType']?.applyFilter(filter.fields[field]);
                        break;
                    case 'downloaded':
                        this.headerRefStore['downloaded']?.applyFilter(filter.fields[field]);
                        break;
                    case 'testColumn':
                        this.headerRefStore['testColumn']?.applyFilter(filter.fields[field]);
                        break;
                    case 'retentionPeriod':
                        this.customExpireDateFilter?.applyFilter(filter.fields[field]);
                        break;
                    case 'taxYear':
                        this.customTaxYearMultiSelect?.applyFilter(filter.fields[field]);
                        break;
                    case 'returnStatus':
                        this.headerRefStore['returnStatus']?.applyFilter(filter.fields[field]);
                        break;
                }
            }

            this.filterChanged = false;
            this.isAppliedFilter = false;
            this.onFilterChange(filter.fields);
            logClientEvent(`${LogEventConstants.Common.Filter.ApplySpecificFilter} ${this.pageTitle}`, {
                count: 1,
                page: filter?.filterType,
            });
            //(this.refs.table as BootstrapTable).handleSearch(filter.searchText);
        }
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (
            <CheckBoxComponent
                size={CheckBoxSize.sm}
                id={'delivered-returns-checkbox-' + props.rowIndex}
                indeterminate={props.indeterminate}
                checked={props.checked}
                disabled={props.disabled}
                onChange={(e: any) => props.onChange(e, props.rowIndex)}
                ref={(input: any) => {
                    if (input) {
                        input.indeterminate = props.indeterminate;
                    }
                }}
                text={''}
            />
        );
    };

    private getOfficeSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customOfficeMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                placeholder={placeholder}
                className={'filter select-filter multi-select-widthauto-office-location'}
                optionClassName={'office-location-custom-multiselect-status'}
            />
        );
    };

    private ReminderDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (
            <CustomDateFilter
                onRef={(ref: any) => (this.customLastReminderFilter = ref)}
                filterHandler={filterHandler}
                customFilterParameters={customFilterParameters}
                calendarContainer={ReactDom.findDOMNode(this.refs.table)}
            />
        );
    };

    private RetentionDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (
            <CustomDateFilter
                onRef={(ref: any) => (this.customExpireDateFilter = ref)}
                filterHandler={filterHandler}
                customFilterParameters={customFilterParameters}
                calendarContainer={ReactDom.findDOMNode(this.refs.table)}
            />
        );
    };

    private getStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options: any = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customStatusMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                placeholder={placeholder}
            />
        );
    };

    private getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options: any = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                placeholder={placeholder}
            />
        );
    };

    private signatureStatusIdType = (cell: any, row: any) => {
        const status: any = row.signatureStatusIdType;
        const signatureType:
            | 'None'
            | 'ESigned'
            | 'ManuallySigned'
            | 'Uploaded'
            | 'AwaitingESign'
            | 'AwaitingUpload'
            | 'Locked'
            | 'MailOrFax'
            | 'SignedAndESigned'
            | 'AutoParsed'
            | 'Processing'
            | 'Delivering'
            | 'Delivered'
            | 'DeliveryFailed'
            | 'Reviewed'
            | 'PartiallySigned'
            | 'PartiallyReviewed' = typeof status === 'number' ? SignatureStatus[status] : status;

        if (
            row.signatureStatusIdType === SignatureStatus[SignatureStatus.AwaitingESign] ||
            row.signatureStatusIdType === SignatureStatus[SignatureStatus.PartiallySigned] ||
            row.signatureStatusIdType === SignatureStatus[SignatureStatus.AwaitingUpload]
        ) {
            if (row.signedCount > 0 || row.reviewedCount > 0) {
                return (
                    <Link to={'#'} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>
                        {SignatureStatusList[signatureType].toUpperCase()}
                    </Link>
                );
            }
        } else if (row.signatureStatusIdType === SignatureStatus[SignatureStatus.PartiallyReviewed]) {
            if (row.reviewedCount > 0 || row.recieverCount !== row.reviewedCount) {
                return (
                    <Link to={'#'} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>
                        {SignatureStatusList[signatureType].toUpperCase()}
                    </Link>
                );
            }
        } else if (row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESigned]) {
            if (row.reviewedCount > 0 || row.recieverCount !== row.signedCount) {
                return (
                    <Link to={'#'} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>
                        {SignatureStatusList[signatureType].toUpperCase()}
                    </Link>
                );
            }
        }
        return <span>{SignatureStatusList[signatureType].toUpperCase()}</span>;
    };

    private deliveredReportAction = (cell: any, row: any) => {
        return (
            <DeliveredReportAction
                pageId={this.props.pageId}
                key={cell}
                rowIndex={row.rowIndex}
                signatureStatus={row.signatureStatusIdType}
                onClientTrackingOpen={this.props.onClientTrackingOpen}
                onDownloadEfileFormsOpen={this.props.onDownloadEfileFormsOpen}
                onDownloadReturnOpen={this.props.onDownloadReturnOpen}
                onEditClientInfoOpen={this.props.onEditClientInfoOpen}
                onRecallReturnOpen={this.props.onRecallReturnOpen}
                onUnlockReturnOpen={this.props.onUnlockReturnOpen}
                onRemoveReport={this.props.onRemoveReport}
                onReportProblemOpen={this.props.onReportProblemOpen}
                onDeleteTaxReturnOpen={this.props.onDeleteTaxReturnOpen}
                onResendAccessLinkOpen={this.props.onResendAccessLinkOpen}
                onTaxpayerView={this.props.onTaxpayerView}
                onViewAccessCodeOpen={this.props.onViewAccessCodeOpen}
                onRetentionPolicyOpen={this.props.onRetentionPolicyOpen}
                onVoucherReminderOpen={this.props.onVoucherReminderOpen}
                title={cell}
                onRestoreReturnOpen={this.props.onRestoreReturnOpen}
                isArchived={this.props.isArchived}
                onUploadFormsOpen={this.props.onUploadFormsOpen}
                onClientViewClick={this.props.onClientViewClick}
                onClientViewLinkClick={this.props.onClientViewLinkClick}
                taxReturn={this.props.taxReturn}
                deliveredReturnData={this.props.delivered.deliveredReturnTableModel.documents}
                onScreenShareViewClick={this.props.onScreenShareViewClick}
                onScreenShareOpen={this.props.onScreenShareOpen}
                showScreenShareSubMenu={this.props.showScreenShareSubMenu}
                id={row.id}
                clientInfo={this.props.clientInfo}
            />
        );
    };

    private downloadedHistory = (cell: any, row: any) => {
        return row.downloaded > 0 &&
            (row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESigned] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.Uploaded] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.AlternateAndUploaded] ||
                row.signatureStatusIdType === SignatureStatus[SignatureStatus.SignedAndESigned]) ? (
            <Link to={'#'} onClick={() => this.props.onDownloadHistoryOpen(row.rowIndex)}>
                Downloaded
            </Link>
        ) : row.bulkDownloadCount > 0 ? (
            <span>Downloaded</span>
        ) : (
            <span>To Be Downloaded</span>
        );
    };
    private createCustomToolBar = (props: ToolBarProps) => {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marR15" style={{ height: 33 }}>
                <Filters
                    ref="Filters"
                    onFilterNameChange={this.props.onFilterNameChange}
                    onFilterSave={this.props.onFilterSave}
                    onClearFilter={this.onClearFilter}
                    onExportToExcel={this.props.onExportToExcel}
                    filterList={this.props.filterList}
                    onSetDefaultFilter={this.props.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                    onFilterDelete={this.props.onFilterDelete}
                    show={this.props.saveFilterShow}
                    onFilterSaveClick={this.onFilterSaveClick}
                    onSaveFilterHide={this.props.onSaveFilterHide}
                    onLoadSelectedFilter={this.onLoadSelectedFilter}
                    defaultFilter={this.props.defaultFilter}
                    loadGrid={this.props.loadGrid}
                    OnOpenColumnSettings={this.OnOpenColumnSettings}
                    proxyFilter={this.props.proxyFilter}
                    onPageReload={this.props.onPageReload}
                    refreshDelay={this.props.refreshDelay}
                    showExportExcel={false}
                    loadSpinner={this.props.loadSpinner}
                    exportRBACKey={RBACKeys[this.props.pageId].exportButton}
                />
            </div>
        );
    };

    private showBulkAlertMessage() {
        const documentsInPage = this.props.delivered.deliveredReturnTableModel.documents.length;
        if (!this.props.isArchived && this.props.totalRows > 20 && this.props.showBulkSelectionMessage) {
            return (
                <div style={{ position: 'absolute', display: 'inline-block', zIndex: 10, padding: '0px 0px' }}>
                    <Alert variant="warning" style={{ padding: '6px', width: 'fit-content', margin: 'auto' }}>
                        <span
                            className="fa fa-exclamation-triangle"
                            style={{ marginRight: '5px', paddingLeft: '5px' }}
                        ></span>
                        All {documentsInPage} records on this page are selected. To select the remaining{' '}
                        {this.props.totalRows - documentsInPage} filtered records,
                        <Link to={'#'} onClick={() => this.props.onBulkSelectionChange(true)}>
                            {' '}
                            click here
                        </Link>
                    </Alert>
                </div>
            );
        }
    }

    private createCustomClearSearchBtn = (onClick: any): any => {
        return (
            <button
                ref={(ins: any) => {
                    this.refClearSearchBtn = ins;
                }}
                className="hidden"
                onClick={onClick}
            >
                Clear
            </button>
        );
    };

    private createCustomSearchField = (props: any): any => {
        return <GlobalSearchField {...props} defaultValue={this.props.currentFilter.searchText} placeholder="Search" />;
    };

    private DownloadedOptionsList = {
        0: 'To Be Downloaded',
        1: 'Downloaded',
    };

    private SignatureStatusOptionsList = [
        { label: 'E-SIGNED', value: 1 },
        { label: 'MANUALLY SIGNED', value: 2 },
        { label: 'UPLOADED', value: 3 },
        { label: 'AWAITING E-SIGN', value: 4 },
        { label: 'AWAITING UPLOAD', value: 5 },
        { label: 'SIGNED AND E-SIGNED', value: 8 },
        { label: 'DELIVERED TO TAXCADDY', value: 10 },
        { label: 'TAXCADDY DELIVERY FAILED', value: 16 },
        { label: 'ALTERNATE DELIVERY', value: 17 },
        //{ label: 'ALTERNATE AND UPLOADED', value: 19 },
        { label: 'REVIEWED', value: 20 },
        { label: 'AWAITING REVIEW', value: 21 },
        { label: 'PARTIALLY SIGNED', value: 22 },
        { label: 'PARTIALLY REVIEWED', value: 23 },
    ];

    private ReturnStatusOptionsList = {
        0: 'Return Not Delivered',
        1: 'Return Delivered',
    };

    private defaultResultStatusType = (cell: any, row: any) => {
        const returnStatus = row.returnStatus != 0 ? 'Return Delivered' : 'Return Not Delivered';
        return (
            <div title={returnStatus} className="ellipsis">
                {returnStatus}
            </div>
        );
    };

    private getColumns = (): any[] => {
        return [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter,
            },
            {
                header: 'Name',
                key: 'taxDocumentName',
                isKey: false,
                dataFormat: this.nameType,
                columnClassName: 'nameColumnClass',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: '180px',
                filter: { type: 'TextFilter', placeholder: 'NAME', style: { font: 'bold' } } as TextFilter,
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' } } as TextFilter,
            },
            {
                header: 'Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: this.customTypeTooltip,
                isHidden: false,
                width: '75px',
                filter: {
                    type: 'SelectFilter',
                    placeholder: 'Select Type...',
                    options: EngagementList,
                } as SelectFilter,
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: '90px',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.getYearMultiSelectDropDown,
                    customFilterParameters: {
                        options: this.taxYearList,
                        enableAllFilter: true,
                        placeholder: 'Select Year...',
                    } as any,
                } as CustomFilter,
            },
            {
                header: 'ERO / Signer',
                key: 'partner',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' } } as TextFilter,
            },
            {
                header: 'Office Location',
                key: 'locationName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: '130px',
                Placeholder: 'Office Location',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.getOfficeSelectDropDown,
                    customFilterParameters: {
                        options: [
                            ...this.props.userLocation.map(location => {
                                return { label: location.locationName, value: location.locationId };
                            }),
                            { label: 'Blanks', value: -100 },
                        ],
                        enableAllFilter: false,
                        placeholder: 'Select Office Location...',
                    } as any,
                },
            },
            {
                header: 'Sent By',
                key: 'deliveredBy',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Sent By', style: { font: 'bold' } } as TextFilter,
            },
            {
                header: 'Delivered Date',
                key: 'deliveredDate',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'SelectFilter',
                    placeholder: 'Select Delivered Date Limit',
                    options: DaysRangeOptionsList,
                } as SelectFilter,
            },
            {
                header: 'Status',
                key: 'status', // String-based value accessors!
                isKey: false,
                dataFormat: this.signatureStatusIdType,
                columnClassName: '',
                dataSort: true,
                toolTip: this.customStatusTooltip,
                isHidden: false,
                width: 'auto',
                Placeholder: 'Status',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.getStatusMultiSelectDropDown,
                    customFilterParameters: {
                        options: this.SignatureStatusOptionsList,
                        enableAllFilter: false,
                        placeholder: 'Select Status...',
                    } as any,
                } as CustomFilter,
            },
            {
                header: 'Return Status',
                key: 'returnStatus',
                isKey: false,
                dataFormat: this.defaultResultStatusType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'SelectFilter',
                    options: this.ReturnStatusOptionsList,
                    placeholder: 'Select Return Status...',
                } as SelectFilter,
            },
            {
                header: 'Last Reminder',
                key: 'lastReminder', // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.ReminderDateFilter,
                } as CustomFilter,
            },
            {
                header: this.props.customColumnHeader, //need to get it from data base
                key: 'testColumn', // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: this.props.customColumnHeader != '' ? false : true,
                width: 'auto',
                filter: {
                    type: 'TextFilter',
                    placeholder: this.props.customColumnHeader,
                    style: { font: 'bold' },
                } as TextFilter, // change the placeholder according to header
            },
            {
                header: 'Downloaded',
                key: 'downloaded', // String-based value accessors!
                isKey: false,
                dataFormat: this.downloadedHistory,
                columnClassName: '',
                dataSort: true,
                toolTip: this.customDownloadedTooltip,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'SelectFilter',
                    options: this.DownloadedOptionsList,
                    placeholder: 'Select Downloaded...',
                } as SelectFilter,
            },
            {
                header: 'Deletion Date',
                key: 'retentionPeriod', // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.RetentionDateFilter,
                } as CustomFilter,
            },
            {
                header: 'Action',
                key: 'button', // String-based value accessors!
                isKey: false,
                dataFormat: this.deliveredReportAction,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: '80px',
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter,
            },
        ];
    };

    private getCustomColumns = (columns: any) => {
        if (this.state.customColumnOptions.length === 0) {
            return columns;
        }
        let columnHeader: any[] = columns;
        if (!this.props.customColumnHeader) {
            let columnState = [...this.state.customColumnOptions];
            let indexTestColumn = columnState.findIndex(a => a.columnName == 'testColumn');
            if (indexTestColumn != -1) {
                columnState.splice(indexTestColumn, 1);
                this.setState({
                    customColumnOptions: columnState,
                    modifiedCustomColumns: columnState,
                });
            }
        } else {
            let columnState = [...this.state.customColumnOptions];
            let index = columnState.findIndex(a => a.columnName == 'testColumn');
            if (index == -1) {
                var insertIndex: number = 10;
                var list: number[] = [];
                var i: number;
                for (i = 1; i < columnState.length; i++) {
                    list.push(i);
                }
                columnState.forEach(col => {
                    list.forEach((e, index) => (e == col.columnOrder ? list.splice(index, 1) : null));
                });
                let notExists: boolean = true;
                if (list.length > 0) {
                    insertIndex = list[0];
                    notExists = false;
                }
                var testColumn: IColumnSettings = {
                    columnName: 'testColumn',
                    columnOrder: insertIndex,
                    isActive: 1,
                };
                var indexA = 0;
                columnState.map((col, index) => {
                    if (col.columnOrder == insertIndex + 1) {
                        indexA = index;
                    }
                    if (notExists && col.columnOrder >= insertIndex) {
                        col.columnOrder = col.columnOrder + 1;
                        return col;
                    }
                    return col;
                });
                columnState.splice(indexA, 0, testColumn);
                this.setState({
                    customColumnOptions: columnState,
                    modifiedCustomColumns: columnState,
                });
            }
        }
        let order: number = 0;
        let removedColumn: any = null;
        for (let i = 0; i < this.state.customColumnOptions.length; i++) {
            for (let j = 0; j < columnHeader.length; j++) {
                if (
                    this.state.customColumnOptions[i].columnName.trim() == columnHeader[j].header.trim() ||
                    this.state.customColumnOptions[i].columnName.trim() == columnHeader[j].key.trim()
                ) {
                    order = this.state.customColumnOptions[i].columnOrder;
                    columnHeader[j].isHidden = !this.state.customColumnOptions[i].isActive;
                    removedColumn = columnHeader.splice(j, 1);
                    columnHeader.splice(order - 1, 0, removedColumn[0]);
                }
            }
        }

        return columnHeader;
    };

    //===================================================================== ColumnSettings ===========================================================================//

    private onDragStart = (event: any, listIndex: any) => {
        let index = listIndex;
        event.dataTransfer.setData('item', index);
    };

    private onDropped = (event: any, listIndex: any) => {
        let copy: IColumnSettings[] =
            this.state.modifiedCustomColumns.length > 0
                ? [...this.state.modifiedCustomColumns]
                : this.getColumnSettings();
        let temp = JSON.parse(JSON.stringify(copy));
        let oldIndex: number = parseInt(event.dataTransfer.getData('item'));
        if (oldIndex != listIndex) {
            let parent: any = document.getElementById('item-list');
            let target: any = parent && parent.children[listIndex];
            let dropped: any = parent && parent.children[oldIndex];
            dropped && dropped.remove();
            let newIndex = listIndex;
            if (newIndex < oldIndex) {
                target && target.closest('li').before(dropped);
            } else {
                target && target.closest('li').after(dropped);
            }

            let childNodes = parent.getElementsByTagName('li');
            for (let j = 0; j < childNodes.length; j++) {
                temp.map((items: any, index: number) => {
                    if (items.columnName == childNodes[j].className) {
                        items.columnOrder = j + 1;
                    }
                });
            }
            temp = temp.sort((a: any, b: any) =>
                a.columnOrder < b.columnOrder ? -1 : a.columnOrder > b.columnOrder ? 1 : 0
            );
            this.setState({ modifiedCustomColumns: temp });
        }
    };

    private activateOrDeactivateCustomColumn = (event: any, name: string) => {
        const { modifiedCustomColumns } = this.state;
        let copy: IColumnSettings[] =
            modifiedCustomColumns.length > 0 ? [...modifiedCustomColumns] : this.getColumnSettings();
        let temp = JSON.parse(JSON.stringify(copy));
        temp.map((items: IColumnSettings) => {
            if (items.columnName == name) {
                if (event.target.checked) {
                    items.isActive = 1;
                } else {
                    items.isActive = 0;
                }
            }
        });
        this.setState({ modifiedCustomColumns: temp });
    };

    private OnSaveCustomColumns = () => {
        this.setState({ showColumnSettingsLoader: true });
        var columnSettings: IColumnSettings[] = this.state.modifiedCustomColumns.map(col => {
            if (col.columnName === this.props.customColumnHeader)
                return {
                    ...col,
                    columnName: 'testColumn',
                };
            else return col;
        });
        this.setState(
            {
                customColumnOptions: columnSettings,
            },
            () => {
                let type: ReportType = ReportType.Delivered;
                if (this.props.isArchived) type = ReportType.Archived;
                this.props.saveColumnSettings(type, this.state.customColumnOptions, this.callback);
                logClientEvent(`${LogEventConstants.Common.Settings.ReportColumnUpdated} ${this.pageTitle}`, {
                    count: 1,
                    page: this.pageTitle,
                });
            }
        );
    };

    private onReset = () => {
        this.setState({
            modifiedCustomColumns: this.state.tempCustomColumns,
        });
        this.OnCloseColumnSettings();
    };

    private callback = () => {
        this.setState({ showColumnSettingsLoader: false });
        this.OnCloseColumnSettings();
        VenusNotifier.Success(DeliveredReturnsConstants.OtherMessage.SaveColumnSettingsSuccess, null);
    };

    private OnCloseColumnSettings = () => {
        this.setState({ showColumnSettings: false });
    };

    private OnOpenColumnSettings = () => {
        this.setState({ showColumnSettings: true });
    };

    private getColumnSettings = (): IColumnSettings[] => {
        return this.getColumns()
            .filter(column => !column.isHidden)
            .map((col, index) => {
                if (col.key == 'testColumn') {
                    return {
                        columnName: 'testColumn',
                        columnOrder: index + 1,
                        isActive: 1,
                    } as IColumnSettings;
                } else {
                    return {
                        columnName: col.header,
                        columnOrder: index + 1,
                        isActive: 1,
                    } as IColumnSettings;
                }
            });
    };

    private getListItem = () => {
        let columnsOptions: IColumnSettings[] = [];
        if (this.state.modifiedCustomColumns.length <= 0) {
            columnsOptions = this.getColumnSettings();
        } else {
            columnsOptions = this.state.modifiedCustomColumns;
        }
        if (!this.props.customColumnHeader) {
            var index = columnsOptions.findIndex(a => a.columnName == 'testColumn');
            if (index != -1) {
                columnsOptions.splice(index, 1);
            }
        }
        let items = columnsOptions
            .filter(col => col.columnName != 'Action')
            .map((value, index) => {
                let isColumnActive: boolean = value.isActive == 1 ? true : false;
                return (
                    <li
                        key={value.columnName + value.columnOrder}
                        className={value.columnName}
                        onDrop={e => this.onDropped(e, index)}
                        draggable={true}
                        onDragStart={e => this.onDragStart(e, index)}
                        onDragOver={e => e.preventDefault()}
                        onDragEnd={e => e.preventDefault()}
                        style={{ listStyleType: 'none', marginBottom: '20px', width: '100%', display: 'flex' }}
                    >
                        <div style={{ width: '100%' }}>
                            <span style={{ float: 'left' }} className="col-sm-5">
                                <input
                                    type="text"
                                    style={{ pointerEvents: 'none' }}
                                    className={'form-control draggable-input noOutline'}
                                    value={
                                        value.columnName == 'testColumn'
                                            ? this.props.customColumnHeader
                                            : value.columnName
                                    }
                                    disabled={value.columnName == 'Client ID' ? true : false}
                                />
                            </span>
                            {value.columnName == 'Client ID' ? (
                                <span style={{ padding: '0px 10px' }} className="col-sm-4">
                                    <i
                                        className="fa fa-info-circle"
                                        title={'This column is required'}
                                        style={{ paddingTop: '10px', color: '#26b2ad', cursor: 'pointer' }}
                                    ></i>
                                </span>
                            ) : (
                                <label className="toggleSwitch col-sm-4">
                                    <input
                                        type="checkbox"
                                        checked={isColumnActive}
                                        onChange={event =>
                                            this.activateOrDeactivateCustomColumn(event, value.columnName)
                                        }
                                    ></input>
                                    <span className="columnFilterSlider round"></span>
                                </label>
                            )}
                        </div>
                    </li>
                );
            });
        return items;
    };

    //=========================================================================================================================================================//

    public render() {
        let columns = this.getColumns();
        const options: any = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            onSearchChange: this.props.onSearchChange,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {},
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            clearSearch: true,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />,
        };

        const pagination = true;

        let selected: number[] = [];

        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push((this.props.pageNo - 1) * this.props.pageSize + rowIndex);
            }
        }

        let selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected',
        };

        const searchEnable = true;

        let Columns = this.getCustomColumns(columns);

        const data = this.props.delivered.deliveredReturnTableModel.documents.map((model, index) => {
            return {
                taxDocumentName: getClientName(model.document),
                clientId: model.document.clientId,
                engagementType: engagementType(model.document.engagementType),
                partner: model.document.partner.lastName + ', ' + model.document.partner.firstName,
                locationName: model.document.location?.locationName,
                deliveredBy:
                    model.document.deliveredByUser == undefined || model.document.deliveredByUser.userId == 0
                        ? ''
                        : model.document.deliveredByUser.lastName + ', ' + model.document.deliveredByUser.firstName,
                deliveredDate: moment(model.document.deliveredOn).format('MM/DD/YYYY'),
                signatureStatusIdType: model.document.signatureStatus,
                lastReminder: model.lastReminderOn == null ? '' : moment(model.lastReminderOn).format('MM/DD/YYYY'),
                testColumn: model.customColumn == null ? '' : model.customColumn,
                downloaded: model.downloadsCount,
                bulkDownloadCount: model.bulkDownloadCount,
                button: 'Actions',
                index: (this.props.pageNo - 1) * this.props.pageSize + index,
                signedCount: model.signedCount,
                id: model.document.id,
                documentKey: model.document.documentGuid,
                taxYear: model.document.taxYear,
                retentionPeriod:
                    model.retentionPeriod == null ? '' : moment(model.retentionPeriod).format('MM/DD/YYYY'),
                rowIndex: index,
                reviewedCount: model.reviewedCount,
                recieverCount: model.recieverCount,
                returnStatus: model.returnStatus,
                isUTELinked: model.isUTELinked,
                taxpayerEmail: model.document.taxpayerEmail,
            };
        });
        return (
            <div className="pos-relative">
                <ColumnSettings
                    activateOrDeactivateColumn={this.activateOrDeactivateCustomColumn}
                    getListItem={this.getListItem}
                    onDragStart={this.onDragStart}
                    onDropped={this.onDropped}
                    onReset={this.onReset}
                    OnSaveCustomColumns={this.OnSaveCustomColumns}
                    showCustomColumn={this.state.showColumnSettings}
                    showLoader={this.state.showColumnSettingsLoader}
                />
                {this.showBulkAlertMessage()}
                <div data-test-auto="B510184C-5041-464B-9904-107F0B3C6F60">
                    <BootstrapTable
                        ref="table"
                        data={data}
                        remote={true}
                        fetchInfo={{ dataTotalSize: this.props.totalRows }}
                        options={options}
                        striped
                        hover={true}
                        pagination={pagination}
                        selectRow={selectRowProp}
                        search={searchEnable}
                    >
                        {Columns.map((value: any, index: any) => {
                            return (
                                <TableHeaderColumn
                                    key={index}
                                    ref={e => (this.headerRefStore[value.key] = e)}
                                    isKey={value.isKey}
                                    dataField={value.key}
                                    hidden={value.isHidden}
                                    width={value.width}
                                    dataFormat={value.dataFormat}
                                    columnClassName={value.columnClassName}
                                    columnTitle={value.toolTip}
                                    filter={value.filter}
                                    dataSort={value.dataSort}
                                >
                                    {' '}
                                    {value.width === 'auto' ? (
                                        <span title={value.header} className="table-text-sort">
                                            {value.header}
                                        </span>
                                    ) : (
                                        <span title={value.header}>{value.header}</span>
                                    )}
                                </TableHeaderColumn>
                            );
                        })}
                    </BootstrapTable>
                </div>
            </div>
        );
    }
}

export default DeliveredReturnsTable;
