import { Guid } from './../../Utilities/Guid';

export interface IEmailTemplateMessage {
    id: number;
    name: string;
    text: string;
    subject: string;
    type: number;
    companyId: number;
}

export interface IWelcomeMessage {
    id: number;
    name: string;
    text: string;
    header: string;
    type: number;
}

export interface IClientMessage {
    id: number;
    name: string;
    text: string;
    type: number;
    companyId: number;
}

export interface IAttachmentInstruction {
    id: number;
    name: string;
    text: string;
    isEditable: boolean;
}

export enum clientInstructions {
    welcomePage = 1,
    k1WelcomePage = 2,
    initialEmail = 3,
    voucherReminder = 4,
    downloadEsignedFroms = 6,
    k1Distribution = 7,
    paymentInstruction = 9,
    groupPaymentInstruction = 11,
    signingReminder = 40,
    k1PartnerInfoUpdate = 41,
    k1ClientInfoUpdate = 42,
    k1PartnerWelcomeMessage = 43,
    k1ClientWelcomeMessage = 44,
    k1PartnerUpdatePageMessage = 45,
    InvoicePaymentSuccessMail = 50,
    groupInitialEmail = 57,
    groupSigningReminder = 58,
    groupVoucherReminder = 59,
    groupDownloadEsignedFroms = 61,
    recalledReturnEmail = 33,
    initialMailForReviewOnly = 65
}

export const initialEmailMessage = {
    id: 0,
    name: "",
    text: "",
    subject: "",
    type: 0,
    companyId: 0
}

export const initialWelcomeMessage = {
    id: 0,
    name: "",
    text: "",
    header: "",
    type: 0
}

export const initialClientMessage = {
    id: 0,
    name: "",
    text: "",
    type: 0,
    companyId: 0
}

export const signingReminderMessage = {
    id: 0,
    name: "",
    text: "",
    type: 0
}

export const initialAttachmentInstruction = {
    id: 0,
    name: '',
    text: '',
    isEditable: false
}
