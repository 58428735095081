import { IOnehubAccessCodeModel } from '../../components/common/OnehubAccessCodeModal';
import { SUITE_API_BASE_URL } from 'src/utils/contants';
import { AppThunkAction } from '..';
import { handleResponse } from '../Library';
import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../common/NotificationStore';

export interface IUTESetting {
    uteEnabled: boolean;
    uteModified: string;
    uteRetentionPolicy: IUTERetentionPolicy;
    subdomain: string;
}

export interface IUTERetentionPolicy {
    uteRetentionPeriod: number;
    notifyExpiry: number;
    retentionPolicyModified: string;
}

export interface ReceiveOnehubSettingAction {
    type: actionTypes.RECEIVE_UTE_SETTING;
    uteSetting: IUTESetting;
}

type KnownAction = ReceiveOnehubSettingAction | NotificationAction;

export const actionCreators = {
    requestUTESetting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(SUITE_API_BASE_URL + 'api/ute-setting', {
            method: 'GET',
            credentials: 'include',
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_UTE_SETTING,
                    uteSetting: data,
                });
            })
            .catch(error => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof statusMessage === 'string') {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: statusMessage,
                        statusType: StatusType.Error,
                    });
                }
            });
    },

    requestOnehubAccessCode:
        (
            primaryEmails: string[],
            callback?: (response: IOnehubAccessCodeModel[]) => void,
            resourceId: string = ''
        ): AppThunkAction<KnownAction> =>
        () => {
            const fetchTask = fetch(SUITE_API_BASE_URL + 'api/one-hub/access-code', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'X-Resource-Id': resourceId,
                    Accept: 'application/json, */*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(primaryEmails),
            })
                .then(handleResponse)
                .then(response => response as Promise<IOnehubAccessCodeModel[]>)
                .then(data => {
                    if (callback) {
                        callback(data);
                    }
                });
            addTask(fetchTask);
        },

    generateOnehubAccessCode:
        (
            primaryEmailAddress: string,
            callBack?: (response: IOnehubAccessCodeModel) => void,
            resourceId: string = ''
        ): AppThunkAction<KnownAction> =>
        () => {
            const fetchTask = fetch(
                SUITE_API_BASE_URL + 'api/one-hub/generate-access-code?primaryEmail=' + primaryEmailAddress,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Resource-Id': resourceId,
                    },
                }
            )
                .then(handleResponse)
                .then(response => {
                    if (callBack) {
                        callBack(response);
                    }
                })
                .catch(error => {});
            addTask(fetchTask);
        },
};

export const unloadedState: IUTESetting = {
    uteEnabled: false,
    uteModified: new Date().toString(),
    uteRetentionPolicy: {
        uteRetentionPeriod: 0,
        notifyExpiry: 0,
        retentionPolicyModified: new Date().toString(),
    },
    subdomain: '',
};

export const reducer: Reducer<IUTESetting> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.RECEIVE_UTE_SETTING:
            return action.uteSetting as IUTESetting;
    }
    return state;
};
