import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import authStore from './auth/reducer';
import * as UserProfileStore from './userManagement/UserProfileStore';
import * as UserSettingStore from './userManagement/UserSettingStore';
import * as TaxDocumentStore from './common/TaxDocumentStore';
import * as UserStore from './userManagement/UserStore';
import * as DeliveredReturnsStore from './reports/DeliveredReturnsStore';

import * as CompanyAssignmentsStore from './assignments/CompanyAssignmentsStore';
import * as CompanyAssignmentsPagesStore from './assignments/CompanyAssignmentsPagesStore';
import * as DeliveredReturnsPagesStore from './reports/DeliveredReturnsPagesStore';
import * as FilterStore from './reports/FilterStore';
import * as PdfStore from './pdf/PdfStore';
import * as CompanyStore from './company/CompanyStore';
import * as SavedMessageStore from './settings/SavedMessageStore';
import * as ClientMessageStore from './settings/ClientMessageStore';
//import * as  WelcomeMessageStore from './settings/WelcomeMessageStore';
import * as EmailMessageStore from './settings/EmailMessageStore';
import * as MailingReturnAddressStore from '../store/common/MailingReturnAddressStore';
import * as NotificationStore from '../store/common/NotificationStore';
import * as CustomColumnStore from './common/CustomColumnStore';
import * as AdditionalDocumentTypeStore from './common/AdditionalDocumentTypeStore';
import * as InUseExtensionsStore from './reports/InUseExtensionsStore';
import * as InUseExtensionsPagesStore from './reports/InUseExtensionsPagesStore';
import * as RecycleReturnsStore from './reports/RecycleReturns/RecycleReturnsStore';
import * as RecycleReturnsPagesStore from './reports/RecycleReturns//RecycleReturnsPagesStore';
import * as VoucherReminderManagementStore from './reports/VoucherReminderManagement/VoucherReminderManagementStore';
import * as VoucherReminderManagementPagesStore from './reports/VoucherReminderManagement/VoucherReminderManagementPagesStore';
import * as AttachmentsStore from '../store/ProcessReturn/AttachmentsStore';
import * as Upload from '../store/common/Upload';
import * as Download from '../store/common/Download';
import * as UserSignatureStore from './common/UserSignatureStore';
import * as ReminderStore from './common/ReminderStore';
import * as VoucherStore from './ProcessReturn/voucher/VoucherStore';
import * as BusinessReturnStore from './settings/BusinessReturnsStore';
import * as CustomAuthorityStore from './common/CustomAuthorityStore';
import * as UploadTaxReturn from '../store/common/uploadTaxReturn';
import * as UserLoginHistory from '../store/userManagement/UserLoginHistoryStore';
import * as SecurityContainerStore from '../store/company/SecurityContainerStore';
import * as K1DeliveredStatusStore from './../store/reports/K1DeliveredStatusReport/K1DeliveredStatusStore';
import * as ColumnSettingsStore from './../store/reports/ColumnSettings/ColumnSettingsStore';
import * as AdditionalEsignDocumentStore from './../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
//import * as WatermarkStore from '../store/settings/WatermarkStore';
import * as ExtensionCompanySettingsStore from '../store/settings/ExtensionCompanySettingsStore';
import * as GroupedExtensionsStore from '../store/groupedExtensions/GroupedExtensionsStore';
import * as GroupedTaxDocumentStore from '../store/groupedExtensions/GroupedTaxDocumentStore';
import * as GroupedExtensionsProcessStore from '../store/groupedExtensions/GroupedExtensionsProcessStore';
import * as SendGroupedExtensionsStore from '../store/groupedExtensions/SendGroupedExtensions/SendGroupedExtensionsStore';
import * as SendGroupedExtensionsHeaderStore from '../store/groupedExtensions/SendGroupedExtensions/SendGroupedExtensionsHeaderStore';
import * as SendGroupedExtensionsPagesStore from '../store/groupedExtensions/SendGroupedExtensions/SendGroupedExtensionsPagesStore';
import * as RecycleGroupedExtensionsStore from '../store/groupedExtensions/RecycleGroupedExtensions/RecycleGroupedExtensionsStore';
import * as RecycleGroupedExtensionsPagesStore from '../store/groupedExtensions/RecycleGroupedExtensions/RecycleGroupedExtensionsPagesStore';
import * as ExtensionUserSettingsStore from '../store/userManagement/ExtensionUserSettingStore';
import * as ResourceListStore from '../store/common/RBACStore';
import * as OneTimePasswordStore from '../store/OTP/OneTimePassword';
import { reducer as LoggerReducer } from './Logger/LoggerStore';
import { reducer as PostAuthStoreReducer } from './../store/PostAuth/PostAuthStore';
import { ApplicationState } from './types';
import * as SuiteTaxSoftware from '../store/SuiteCompany/TaxSoftwareStore';
import * as PasswordPolicyStore from '../store/PasswordPolicy/PasswordPolicyStore';
import * as OfficeLocationStore from './common/OfficeLocationStore';
import * as UserOfficeLocationStore from './common/UserOfficeLoacationStore';
import * as MFASettingsStore from '../store/company/SuiteMFASettingStore';
import * as OneHubStore from './common/OnehubStore';

export * from './types';

export const reducers = {
    auth: authStore,
    userProfile: UserProfileStore.reducer,
    userSettings: UserSettingStore.reducer,
    deliveredReturns: DeliveredReturnsStore.reducer,
    nextSignerDetails: DeliveredReturnsStore.nextSignerReducer,
    deliveredReturnsPages: DeliveredReturnsPagesStore.reducer,
    companyAssignments: CompanyAssignmentsStore.reducer,
    companyAssignmentsPages: CompanyAssignmentsPagesStore.reducer,
    taxDocuments: TaxDocumentStore.reducer,
    reportFilters: FilterStore.reducer,
    pdfDocuments: PdfStore.reducer,
    companyData: CompanyStore.reducer,
    extensionCompanyData: ExtensionCompanySettingsStore.reducer,
    inUseExtensions: InUseExtensionsStore.reducer,
    inUseExtensionsPages: InUseExtensionsPagesStore.reducer,
    recycleReturns: RecycleReturnsStore.reducer,
    recycleReturnsPages: RecycleReturnsPagesStore.reducer,
    voucherReminderReport: VoucherReminderManagementStore.reducer,
    voucherReminderStatusPages: VoucherReminderManagementPagesStore.reducer,
    mailingReturnAddresses: MailingReturnAddressStore.reducer,
    notification: NotificationStore.reducer,
    savedMessage: SavedMessageStore.reducer,
    clientMessage: ClientMessageStore.reducer,
    //welcomeMessage: WelcomeMessageStore.reducer,
    emailMessage: EmailMessageStore.reducer,
    customColumn: CustomColumnStore.reducer,
    additionalDocumentType: AdditionalDocumentTypeStore.reducer,
    attachments: AttachmentsStore.reducer,
    UploadStore: Upload.reducer,
    DownloadStore: Download.reducer,
    userSignatures: UserSignatureStore.reducer,
    reminderData: ReminderStore.reducer,
    voucherStore: VoucherStore.reducer,
    businessReturnInstructions: BusinessReturnStore.reducer,
    UploadTaxReturnStore: UploadTaxReturn.reducer,
    customAuthority: CustomAuthorityStore.reducer,
    userLoginHistory: UserLoginHistory.reducer,
    securityContainerStore: SecurityContainerStore.reducer,
    k1DeliveredStatusState: K1DeliveredStatusStore.reducer,
    columnSettingsState: ColumnSettingsStore.reducer,
    usersStore: UserStore.reducer,
    additionalEsignDocument: AdditionalEsignDocumentStore.reducer,
    //watermarkStore: WatermarkStore.reducer,
    groupedExtensionsStore: GroupedExtensionsStore.reducer,
    groupedExtensionsProcessStore: GroupedExtensionsProcessStore.reducer,
    groupedTaxDocumentStore: GroupedTaxDocumentStore.reducer,
    sendGroupedExtensionsStore: SendGroupedExtensionsStore.reducer,
    sendGroupedExtensionsHeaderStore: SendGroupedExtensionsHeaderStore.reducer,
    sendGroupedExtensionsPagesStore: SendGroupedExtensionsPagesStore.reducer,
    recycleGroupedExtensions: RecycleGroupedExtensionsStore.reducer,
    recycleGroupedExtensionsPages: RecycleGroupedExtensionsPagesStore.reducer,
    extensionUserSettings: ExtensionUserSettingsStore.reducer,
    loggerStore: LoggerReducer,
    postAuthDataStore: PostAuthStoreReducer,
    rbacStore: ResourceListStore.reducer,
    taxSoftwareData: SuiteTaxSoftware.reducer,
    otpData: OneTimePasswordStore.reducer,
    passwordPolicyStore: PasswordPolicyStore.reducer,
    officeLocation: OfficeLocationStore.reducer,
    userOfficeLocation: UserOfficeLocationStore.reducer,
    mfaSettingsStore: MFASettingsStore.reducer,
    uteSetting: OneHubStore.reducer,
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}

export function configureStore(initialState?: ApplicationState) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    // If devTools is installed, connect to it
    const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunk))(createStore);

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = combineReducers(reducers);
    const store = createStoreWithMiddleware(allReducers, initialState);
    return store;
}

const store = configureStore();

export default store;
