import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { IEmailTemplateMessage, clientInstructions } from '../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { handleResponse } from '../Library';
import * as Constants from '../../../src/components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { ICompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ExtensionCompanySettings } from '../../Core/ViewModels/Extension/ExtensionComponentModels';
import * as CompanyStore from '../company/CompanyStore';
import * as ExtensionCompanySettingsStore from '../../store/settings/ExtensionCompanySettingsStore';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

export interface IEmailMessageStoreState {
    data: IEmailMessageDictionary;
    isLoading: boolean;
}

export interface IEmailMessageDictionary {
    [index: number]: IEmailMessageState
}

export interface IEmailMessageState {
    emailMessage: IEmailTemplateMessage;
}

interface RequestEmailMessasgeListAction {
    type: actionTypes.REQUEST_EMAIL_MESSAGES;
}

interface ReceiveEmailMessageListAction {
    type: actionTypes.RECEIVE_EMAIL_MESSAGES;
    messageDetails: IEmailTemplateMessage;
    id: number;
}

interface DeleteEmailMessageDetail {
    type: actionTypes.DELETE_EMAIL_MESSAGE;
    deletedMessageDetail: IEmailTemplateMessage;
}

type KnownAction =
    RequestEmailMessasgeListAction |
    ReceiveEmailMessageListAction |
    DeleteEmailMessageDetail |
    NotificationAction

export const ActionCreators = {
    requestEmailMessages: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/EmailTemplate/GetEmailTemplate`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<IEmailTemplateMessage[]>)
            .then((data) => {
                data.map((value, index) => {
                    dispatch({
                        type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                        messageDetails: value, id: value.id
                    });
                });
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.FetchMessageError, statusType: StatusType.Error
                });
                logger.trackError(`requestEmailMessages failed with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_EMAIL_MESSAGES });
    },

    saveEmailMessageDetail: (message: IEmailTemplateMessage, companySettings: ExtensionCompanySettings, isSetDefault: boolean, typeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/EmailTemplate`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(message),            
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let addedMessage: IEmailTemplateMessage = Object.assign({}, message);
                addedMessage.id = newId;
                if (isSetDefault) {
                    switch(typeId){
                            case clientInstructions.initialEmail:
                                companySettings.defaultSettings.initialEmail = newId;
                                break;
                            case clientInstructions.voucherReminder:
                                companySettings.defaultSettings.voucherReminders = newId;
                                break;
                            case clientInstructions.downloadEsignedFroms:
                                companySettings.defaultSettings.downloadSignedForms = newId;
                                break;
                            case clientInstructions.signingReminder:
                                companySettings.defaultSettings.signingReminder = newId;
                                break;
                            case clientInstructions.paymentInstruction:
                                companySettings.defaultSettings.paymentInstruction = newId;
                                break;
                                case clientInstructions.recalledReturnEmail:
                            companySettings.defaultSettings.recalledReturnMail = newId;
                                break;
                                case clientInstructions.initialMailForReviewOnly:
                            companySettings.defaultSettings.reviewOnlyEmail = newId;
                                break;
                            default:
                                break;
                        }

                    let action: any = ExtensionCompanySettingsStore.ActionCreators.updateExtensionCompanySettings(companySettings)
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                        messageDetails: addedMessage, id: newId
                    });
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                        messageDetails: addedMessage, id: newId
                    });
                }
                
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageSuccess, statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageError, statusType: StatusType.Error
                });
                logger.trackError(`saveEmailMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    saveGroupEmailMessageDetail: (message: IEmailTemplateMessage, companySettings: ExtensionCompanySettings, isSetDefault: boolean, typeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/EmailTemplate`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let addedMessage: IEmailTemplateMessage = Object.assign({}, message);
                addedMessage.id = newId;
                if (isSetDefault) {
                    if (typeId == clientInstructions.groupInitialEmail)
                        companySettings.defaultGroupSettings.groupInitialEmail = newId
                    else if (typeId == clientInstructions.groupSigningReminder)
                        companySettings.defaultGroupSettings.groupSigningReminder = newId
                    else if (typeId == clientInstructions.groupVoucherReminder)
                        companySettings.defaultGroupSettings.groupVoucherReminders = newId
                    else if (typeId == clientInstructions.groupDownloadEsignedFroms)
                        companySettings.defaultGroupSettings.groupDownloadSignedForms = newId

                    let action: any = ExtensionCompanySettingsStore.ActionCreators.updateExtensionCompanySettings(companySettings)
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                        messageDetails: addedMessage, id: newId
                    });
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                        messageDetails: addedMessage, id: newId
                    });
                }

                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageSuccess, statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageError, statusType: StatusType.Error
                });
                logger.trackError(`saveGroupEmailMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    updateEmailMessageDetail: (message: IEmailTemplateMessage): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/EmailTemplate`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                    messageDetails: message, id: message.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.UpdateMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.UpdateMessageError, statusType: StatusType.Error
                });
                logger.trackError(`updateEmailMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    deleteEmailMessageDetail: (message: IEmailTemplateMessage): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/EmailTemplate`, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_EMAIL_MESSAGE,
                    deletedMessageDetail: message
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.DeleteMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.DeleteMessageError, statusType: StatusType.Error
                });
                logger.trackError(`deleteEmailMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    }
}

const emailMessageDic: IEmailMessageDictionary = [];

const unloadedState: IEmailMessageStoreState =
    {
        data: { ...emailMessageDic },
        isLoading: true
    } as IEmailMessageStoreState;

export const reducer: Reducer<IEmailMessageStoreState> = (state: IEmailMessageStoreState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_EMAIL_MESSAGES:
            return {
                ...unloadedState
            } as IEmailMessageStoreState;

        case actionTypes.RECEIVE_EMAIL_MESSAGES:
            let received: IEmailMessageStoreState = { ...state };
            received.data[action.id] = { emailMessage: action.messageDetails };
            received.isLoading = false;
            return received;

        case actionTypes.DELETE_EMAIL_MESSAGE:
            let deleteMessage: IEmailMessageStoreState = { ...state }
            if (deleteMessage.data[action.deletedMessageDetail.id]) {
                delete deleteMessage.data[action.deletedMessageDetail.id];
                deleteMessage.isLoading = false;
            }
            return deleteMessage;
    }
    return state || unloadedState;
};