import React from  'react';
import {
    Col, FormLabel, FormControl, FormGroup, Row, Button,/*, Panel*/
    Accordion,
    Card
} from 'react-bootstrap';
import { VoucherTabConstants, customAuthority } from '../../../helper/Constants';
import { CustomDatePicker } from '../../controls/CustomDatePicker';
import { CurrencyTextBox } from '../../../common/controls/CurrencyTextBox';
import moment from "moment";
import {
    getAllStateImages, getVoucherTypes, StateUSA,
    VoucherNo, VoucherTypes, VoucherMode, ITaxReturn,
    EngagementType, SelectOptions, DocumentGroups,
    CustomType, ITaxingAuthority, IVoucher, initialVoucher
} from '../../../common/TaxReturn';
import { IVoucherTaxFilingDate } from '../../../../Core/ViewModels/Voucher/VoucherDefaultDateViewModel';
import { RadioButtonComponent } from '../../../common/RadioButtonComponent';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import { ConvertDateToUtc } from '../../../helper/Validations';
import { AutoSuggestTextbox } from '../../../common/controls/AutoSuggestTextbox';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { DueDateType } from 'src/Core/ViewModels/Extension/ExtensionComponentModels';
import { filterTaxFilingDates } from 'src/components/helper/VoucherNameFilter';

export interface IVoucherEntryProps {
    voucher: IVoucher;
    voucherDefaultDate: IVoucherTaxFilingDate[];
    taxingAuthorities: ITaxingAuthority[];
    onUpdateAddedVoucher(voucher: IVoucher, currentVoucherClone: IVoucher, taxingAuthority: ITaxingAuthority): void;
    taxReturn?: ITaxReturn;
    voucherStore?: IVoucherState;
    isPaymentUrlSame: boolean;
    masterPaymentUrl: string;
}

export interface IVoucherEntryState {
    voucher: IVoucher;
    amount: number;
    enableUpdate: boolean;
    open: boolean;
    isPaymentUrlSame: boolean;
    masterPaymentUrl: string;
    isMasterUrl: boolean;
    currentVoucherClone: IVoucher;
    paymentFormNames: SelectOptions[];
    estimatedFormNames: SelectOptions[];
}

export class VoucherEntry extends React.Component<IVoucherEntryProps, IVoucherEntryState> {

    constructor(props: IVoucherEntryProps) {
        super(props);
        const { voucher } = props;
        this.state = {
            amount: 0,
            voucher: (voucher) ? voucher : initialVoucher,
            enableUpdate: false,
            open: true,
            isPaymentUrlSame: true,
            masterPaymentUrl: "",
            isMasterUrl: false,
            currentVoucherClone: (voucher) ? voucher : initialVoucher,
            paymentFormNames: [],
            estimatedFormNames: []
        }
    }

    componentWillReceiveProps(nextProps: IVoucherEntryProps) {
        const { voucher, voucher: { amount }, isPaymentUrlSame, masterPaymentUrl } = nextProps;
        if (nextProps && nextProps.voucher) {
            var propsVoucher = JSON.parse(JSON.stringify(voucher));
            propsVoucher.dueDate = (propsVoucher.dueDate) ? propsVoucher.dueDate : new Date();
            this.clearPaymentFormNameState();
            this.setInitialVoucherFormName(voucher);
            this.setState({
                amount: amount,
                voucher: propsVoucher,
                isPaymentUrlSame: isPaymentUrlSame,
                masterPaymentUrl: masterPaymentUrl,
                isMasterUrl: !isPaymentUrlSame && voucher.onlinePaymentUri === masterPaymentUrl,
                currentVoucherClone: propsVoucher,
                enableUpdate: false
            });
        }
    }

    componentDidMount() {
        let isPaymentUrlSame: boolean = this.props.isPaymentUrlSame;
        let masterPaymentUrl: string = this.props.masterPaymentUrl;
        let voucher: IVoucher = this.props.voucher;
        this.clearPaymentFormNameState();
        this.setInitialVoucherFormName(voucher);
        this.setState({
            voucher: this.props.voucher,
            amount: this.props.voucher.amount,
            isPaymentUrlSame: isPaymentUrlSame,
            masterPaymentUrl: masterPaymentUrl,
            isMasterUrl: !isPaymentUrlSame && voucher.onlinePaymentUri === masterPaymentUrl
        });
    }

    private setPaymentUrl(tempVoucher: IVoucher, taxingAuthority: ITaxingAuthority) {
        let editedPaymentUrl: string = '';
        let customPaymentUrl: string = '';
        let masterPaymentUrl: string = '';
        let isPaymentUrlSame: boolean = true;
        let isMasterUrl: boolean = true;
        if (tempVoucher.customType === CustomType.OnlinePaymentRequired && tempVoucher.voucherNo > 0) {
            editedPaymentUrl = this.getPaymentUrl(tempVoucher);
            masterPaymentUrl = this.getMasterPaymentUrl(tempVoucher);
            customPaymentUrl = taxingAuthority.PaymentURL;
            if (taxingAuthority.CustomAuthority) {
                isPaymentUrlSame = true;
                tempVoucher.onlinePaymentUri = customPaymentUrl;
                isMasterUrl = false;
            }
            else if (masterPaymentUrl === null || masterPaymentUrl === '') {
                tempVoucher.onlinePaymentUri = editedPaymentUrl;
                isPaymentUrlSame = true;
                isMasterUrl = false;
            }
            else if (editedPaymentUrl != null && editedPaymentUrl != '') { 
                isPaymentUrlSame = editedPaymentUrl === masterPaymentUrl;
                isMasterUrl = !isPaymentUrlSame;
                tempVoucher.onlinePaymentUri = editedPaymentUrl;
            }
            else {
                tempVoucher.onlinePaymentUri = masterPaymentUrl;
                isPaymentUrlSame = true;
                isMasterUrl = false;
            }

            this.setState({
                isPaymentUrlSame: isPaymentUrlSame,
                masterPaymentUrl: masterPaymentUrl,
                isMasterUrl: isMasterUrl
            });
        }
    }

    private handleAuthorityChange = (event: any) => {
        this.clearPaymentFormNameState();
        const authorities: ITaxingAuthority[] = this.props.taxingAuthorities;
        let selectedAuthority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id == event.currentTarget.value)]
        let tempVoucher: IVoucher = { ...this.state.voucher };
        let selectedStateImages: { imageName: string, stateUSA: StateUSA } | undefined;
        selectedStateImages = getAllStateImages().find(img => img.stateUSA ==
            StateUSA[event.target[event.target.selectedIndex].getAttribute('data-abbreviation')]);
        tempVoucher.authorityImage = selectedStateImages !== undefined ? selectedStateImages.imageName
            : "no-image.png";

        if (selectedAuthority) {
            tempVoucher.authorityID = parseInt(event.currentTarget.value);
            tempVoucher.authorityName = selectedAuthority.AuthorityName;
            tempVoucher.customType = CustomType.OnlinePaymentRequired;
        }
        if (tempVoucher.authorityID < customAuthority.customAuthorityId) {
            this.setVoucherFormName(tempVoucher);
        }
        if (selectedAuthority != undefined) {
            this.setPaymentUrl(tempVoucher, selectedAuthority);
            tempVoucher.customType = (tempVoucher.onlinePaymentUri !== "" && tempVoucher.onlinePaymentUri !== undefined) ? CustomType.OnlinePaymentRequired : CustomType.None;
        }
        this.assignVoucherDueDate(tempVoucher);
        this.setState({ voucher: tempVoucher, enableUpdate: true });
    }
    private hasVoucherHasPrintedDueDate = (voucher: IVoucher): boolean => {
        let minDate: any = "0001-01-01T00:00:00";
        if (voucher.printedDueDate && voucher.printedDueDate !== minDate) {
            return true;
        } else {
            return false;
        }
    }
    private assignVoucherDueDate = (voucher: IVoucher) => {
        if (voucher.voucherDueDateType === DueDateType.Printed) {
            voucher.dueDate = voucher.printedDueDate;
        }
        else {
            this.setDueDate(voucher);
        }
    }

    private handleDueDateTypeChange = (event: any) => {
        let selectedDueDateType = parseInt(event.target.value);
        let defaultDueDateType = DueDateType.SSExtDefault;
        let tempVoucher: IVoucher = { ...this.state.voucher };

        if (selectedDueDateType === DueDateType.Printed) {
            if (!this.hasVoucherHasPrintedDueDate(tempVoucher)) {
                VenusNotifier.Warning(VoucherTabConstants.NoPrintedDueDate, "");
                return;
            }
            tempVoucher.voucherDueDateType = DueDateType.Printed;
        }
        else if (selectedDueDateType === DueDateType.SSExtDefault ||
            selectedDueDateType === 0) {
            tempVoucher.voucherDueDateType = DueDateType.SSExtDefault;
        }

        this.assignVoucherDueDate(tempVoucher);
        this.setState({ voucher: tempVoucher, enableUpdate: true });
    }

    private handlePaymentTypeChange = (event: any) => {
        this.clearPaymentFormNameState();
        let tempVoucher: IVoucher = { ...this.state.voucher };
        tempVoucher.voucherNo = parseInt(event.target.value);
        tempVoucher.paymentType = getVoucherTypes(event.target.value);
        const taxingAuthority: any = this.props.taxingAuthorities.find(x => x.Id == tempVoucher.authorityID);
        this.setDueDate(tempVoucher);
        if (tempVoucher.authorityID < customAuthority.customAuthorityId) {
            this.setVoucherFormName(tempVoucher);
        }
        if (taxingAuthority != undefined)
            this.setPaymentUrl(tempVoucher, taxingAuthority);
        this.setState({ voucher: tempVoucher, enableUpdate: true });
    }

    private handleDueDateChange = (date: any) => {
        let tempVoucher: IVoucher = { ...this.state.voucher };
        tempVoucher.dueDate = date;
        this.setState({ voucher: tempVoucher, enableUpdate: true });
    }

    private handleVoucherAmountChange = (value: number) => {
        this.setState({
            amount: value,
            enableUpdate: true
        });
    }

    private handleVoucherAmountBlur = (value: number) => {
        this.setState({
            amount: value
        });
    }

    private handleUpdate = () => {
        let tempVoucher: IVoucher = { ...this.state.voucher };
        const taxingAuthority: any = this.props.taxingAuthorities.find(x => x.Id == tempVoucher.authorityID);
        if (this.state.isMasterUrl) {
            tempVoucher.onlinePaymentUri = this.state.masterPaymentUrl;
        }
        if (taxingAuthority)
            tempVoucher.isMasterPaymentUrl = this.state.isMasterUrl ? true : !taxingAuthority.CustomAuthority && this.state.masterPaymentUrl === this.state.voucher.onlinePaymentUri ? true : false;
        tempVoucher.amount = this.state.amount;

        if (this.state.voucher.dueDate)
            tempVoucher.dueDate = this.state.voucher.dueDate;

        this.setState({ enableUpdate: false, isMasterUrl: true });
        this.props.onUpdateAddedVoucher(tempVoucher, this.state.currentVoucherClone, taxingAuthority);
    }

    private handleCustomTextChange = (event: any) => {
        let tempVoucher: IVoucher = { ...this.state.voucher };
        tempVoucher.customText = event.target.value;
        this.setState({ voucher: tempVoucher, enableUpdate: true });
    }

    private handlePaymentUrlChange = (event: any) => {
        let tempVoucher: IVoucher = { ...this.state.voucher };
        tempVoucher.onlinePaymentUri = event.target.value;
        this.setState({ voucher: tempVoucher, enableUpdate: true });
    }

    private handlePaymentMethodChange = (event: any) => {
        let tempVoucher: IVoucher = { ...this.state.voucher };
        tempVoucher.customType = parseInt(event.target.value);
        const taxingAuthority: any = this.props.taxingAuthorities.find(x => x.Id == tempVoucher.authorityID);
        if (taxingAuthority != undefined)
            this.setPaymentUrl(tempVoucher, taxingAuthority);
        this.setState({ voucher: tempVoucher, enableUpdate: true });
    }

    private handleFormNameChange = (event: any) => {
        let _self = this;
        let tempVoucher: IVoucher = { ...this.state.voucher };
        tempVoucher.formName = event.target.value;
        _self.setState({ voucher: tempVoucher, enableUpdate: true });
    }

    private onCancel = () => {
        this.setState({
            voucher: this.props.voucher,
            amount: this.props.voucher.amount,
            enableUpdate: false
        });
    }

    private setDueDate(voucher: IVoucher) {
        var dueDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        voucher.dueDate = ConvertDateToUtc(dueDate);
        if (voucher.authorityID !== initialVoucher.authorityID
            && voucher.voucherNo !== initialVoucher.voucherNo) {
            const voucherDueDate: any = this.props.voucherDefaultDate
                .find(x => x.authorityId === voucher.authorityID);
            if (voucherDueDate) {
                switch (voucher.voucherNo) {
                    case VoucherNo.PaymentVoucher:
                        voucher.dueDate = voucherDueDate.taxFilingDate ? voucherDueDate.taxFilingDate : voucher.dueDate;
                        break;
                    case VoucherNo.Q1:
                        voucher.dueDate = voucherDueDate.q1;
                        break;
                    case VoucherNo.Q2:
                        voucher.dueDate = voucherDueDate.q2;
                        break;
                    case VoucherNo.Q3:
                        voucher.dueDate = voucherDueDate.q3;
                        break;
                    case VoucherNo.Q4:
                        voucher.dueDate = voucherDueDate.q4;
                        break;
                }
            }
        }
    }

    private getPaymentUrl = (voucher: IVoucher): string => {
        let paymentUrl: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID &&
            voucher.customType === CustomType.OnlinePaymentRequired) {
            if (this.props.voucherStore != undefined && this.props.voucherStore.paymentUrls.length > 0) {
                const paymentUrlInfo: any = this.props.voucherStore.paymentUrls
                    .find(x => x.authorityId === voucher.authorityID
                        && x.engagementType === Number(EngagementType[this.props.taxReturn!.engagementType])
                        && x.voucherType === voucher.paymentType
                    );
                if (paymentUrlInfo) {
                    paymentUrl = paymentUrlInfo.onlinePaymentUrl;
                }
            }
        }
        return paymentUrl;
    }

    private getMasterPaymentUrl = (voucher: IVoucher): string => {
        let paymentUrl: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID &&
            voucher.customType === CustomType.OnlinePaymentRequired) {
            if (this.props.voucherStore != undefined && this.props.taxReturn != undefined) {
                const voucherDefaultDates: any = this.props.voucherStore.voucherDefaultDates
                    .filter(x => x.authorityId === voucher.authorityID &&
                        x.taxYear === this.props.taxReturn!.taxYear &&
                        x.returnType === Number(EngagementType[this.props.taxReturn!.engagementType]));
                const paymentUrlInfo: any = filterTaxFilingDates(voucherDefaultDates, voucher.formName, voucher.paymentType)
                if (paymentUrlInfo) {
                    switch (voucher.voucherNo) {
                        case VoucherNo.PaymentVoucher:
                            paymentUrl = paymentUrlInfo.paymentURL;
                            break;
                        case VoucherNo.Q1:
                        case VoucherNo.Q2:
                        case VoucherNo.Q3:
                        case VoucherNo.Q4:
                            paymentUrl = paymentUrlInfo.esPaymentURL;
                            break;
                        default:
                            paymentUrl = '';
                            break;
                    }
                }
            }
        }
        return paymentUrl;
    }

    private onPaymentUrlChangeRadio = (event: any) => {
        if (event.target.value == 1) {
            this.setState({
                isMasterUrl: true,
                enableUpdate: true
            });
        }
        else {
            this.setState({
                isMasterUrl: false,
                enableUpdate: true
            });
        }
    }

    private onFormNameChangeDropdown = (value: any) => {
        let tempVoucher: IVoucher = { ...this.state.voucher };
        const paymentType: VoucherTypes = tempVoucher.voucherNo > 1 ? VoucherTypes.EstimatedVoucher : VoucherTypes.PaymentVoucher;
        if (this.props.taxReturn) {
            let voucherIndex = this.props.taxReturn.formGroups
                .findIndex(x => x.group == DocumentGroups.Vouchers);
            if (voucherIndex > 0) {
                let voucherForms: IVoucher[] = this.props.taxReturn.formGroups[voucherIndex].forms as IVoucher[];
                voucherForms.filter(x => x.authorityID === tempVoucher.authorityID && x.paymentType === paymentType).map((voucher, i) => {
                    voucher.isFormNameUpdated = false
                });
            }
        }
        tempVoucher.formName = value;
        tempVoucher.isFormNameUpdated = true;
        this.setState({ voucher: tempVoucher, enableUpdate: true });
    };

    private getMasterFormName = (voucher: IVoucher): string => {
        let formName: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID) {
            if (this.props.voucherStore != undefined && this.props.taxReturn != undefined) {
                const masterFormName: any = this.props.voucherStore.voucherDefaultDates
                    .find(x => x.authorityId === voucher.authorityID &&
                        x.taxYear === this.props.taxReturn!.taxYear &&
                        x.returnType === Number(EngagementType[this.props.taxReturn!.engagementType]));
                if (masterFormName) {
                    switch (Number(voucher.voucherNo)) {
                        case VoucherNo.PaymentVoucher:
                            formName = masterFormName.voucherName ? masterFormName.voucherName : "";
                            break;
                        case VoucherNo.Q1:
                        case VoucherNo.Q2:
                        case VoucherNo.Q3:
                        case VoucherNo.Q4:
                            formName = masterFormName.esVoucherName ? masterFormName.esVoucherName : "";
                            break;
                        default:
                            formName = '';
                            break;
                    }
                }
            }
        }
        return formName;
    }

    private getTenantFormName = (voucher: IVoucher): string => {
        let formName: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID) {
            if (this.props.voucherStore != undefined && this.props.voucherStore.voucherFormNames.length > 0) {
                const tenantFormName: any = this.props.voucherStore.voucherFormNames
                    .find(x => x.authorityId === voucher.authorityID
                        && x.engagementType === Number(EngagementType[this.props.taxReturn!.engagementType])
                        && x.voucherType === voucher.paymentType
                    );
                if (tenantFormName) {
                    formName = tenantFormName.formName;
                }
            }
        }
        return formName;
    }

    private setFormNameDropdownValues(type: VoucherTypes, masterFormName: string, tenantFormName: string, voucherFormName?: string) {
        let ddIndex: number = 0;
        if (masterFormName) {
            this.manageFormNameState(type, ddIndex, masterFormName);
            ddIndex++;
        }
        if (tenantFormName) {
            this.manageFormNameState(type, ddIndex, tenantFormName);
            ddIndex++;
        }
        if (voucherFormName) {
            this.manageFormNameState(type, ddIndex, voucherFormName);
            ddIndex++;
        }
    }

    private manageFormNameState = (type: VoucherTypes, ddIndex: number, formName: string) => {
        if (type === VoucherTypes.PaymentVoucher && this.state.paymentFormNames.findIndex(x => x.label === formName) < 0) {
            this.state.paymentFormNames.push({ value: ddIndex.toString(), label: formName })
        }
        else {
            if (type === VoucherTypes.EstimatedVoucher && this.state.estimatedFormNames.findIndex(x => x.label === formName) < 0) {
                this.state.estimatedFormNames.push({ value: ddIndex.toString(), label: formName });
            }
        }
    }

    private setInitialVoucherFormName(tempVoucher: IVoucher) {
        let tenantFormName: string = '';
        let masterFormName: string = '';
        if (tempVoucher.voucherNo > 0 && tempVoucher.authorityID < customAuthority.customAuthorityId) {
            tenantFormName = this.getTenantFormName(tempVoucher);
            masterFormName = this.getMasterFormName(tempVoucher);
            this.setFormNameDropdownValues(tempVoucher.paymentType, masterFormName, tenantFormName, tempVoucher.formName);
        }
    }

    private clearPaymentFormNameState = () => {
        this.state.paymentFormNames.length = 0;
        this.state.estimatedFormNames.length = 0;
    }

    private setVoucherFormName(tempVoucher: IVoucher) {
        let tenantFormName: string = '';
        let masterFormName: string = '';
        if (tempVoucher.voucherNo > 0) {
            tenantFormName = this.getTenantFormName(tempVoucher);
            masterFormName = this.getMasterFormName(tempVoucher);
            tempVoucher.formName = (tenantFormName !== null && tenantFormName !== undefined && tenantFormName !== '') ? tenantFormName : masterFormName;
            this.setFormNameDropdownValues(tempVoucher.paymentType, masterFormName, tenantFormName);
        }
    }

    toggleExpand = () => {
        this.setState({ open: !this.state.open })
    }

    public render() {
        let authorities: ITaxingAuthority[] = this.props.taxingAuthorities;
        let icon = this.state.open ? "fas fa-minus" : "fas fa-plus";
        var currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        return (<div>

            <Accordion defaultActiveKey="0" style={{ marginRight: "1px", marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle
                        style={{ padding: "10px" }}
                        as={Card.Header}
                        variant="link"
                        eventKey="0">
                        <Card.Title as="h4"
                            onClick={() => this.toggleExpand()}>
                            <i className={icon}></i> Voucher Details
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <div>
                            <Card.Body style={{ padding: 10 }}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Row className="marB15">
                                        <label className="text-left control-label div-flex col-sm-3">
                                            Authority
                            </label>
                                        <Col sm={9}>
                                            <select className="form-control"
                                                id="dd-authority"
                                                value={this.state.voucher.authorityID}
                                                data-test-auto="D5B9D6CF-B202-4C03-A659-FE8EB87324B4"
                                                onChange={this.handleAuthorityChange}>
                                                <option value={0} data-abbreviation=''>Select</option>
                                                {authorities.map((authority, index) => {
                                                    return <option key={index} value={authority.Id} data-abbreviation={authority.Abbreviation}>
                                                        {authority.AuthorityName}
                                                    </option>
                                                })}
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="marB15">
                                        <label className="text-left control-label div-flex col-sm-3" >
                                            {VoucherTabConstants.AddVoucher.PaymentType}
                                        </label>
                                        <Col sm={9}>

                                            <select className="form-control"
                                                id="dd-payment-type"
                                                value={this.state.voucher.voucherNo}
                                                data-test-auto="AD205186-7B63-4249-A57B-A1FCEA2C1356"
                                                onChange={this.handlePaymentTypeChange}>
                                                {
                                                    VoucherTabConstants.AddVoucher.voucherNumbers.map((voucherNo, index) => {
                                                        return <option key={index} value={voucherNo.value}>{voucherNo.label}</option>
                                                    })
                                                }
                                            </select>

                                        </Col>
                                    </Row>
                                    <Row className="marB15">
                                        <label className="text-left control-label div-flex col-sm-3">
                                            {VoucherTabConstants.SelectDueDate}
                                        </label>
                                        <Col sm={9}>

                                            <select className="form-control"
                                                id="dd-payment-type"
                                                value={this.state.voucher.voucherDueDateType && this.state.voucher.voucherDueDateType.toString()}
                                                data-test-auto="810D15C4-3E7B-4A5C-9DF4-8FCDFF2515E5"
                                                onChange={this.handleDueDateTypeChange}>
                                                {
                                                    VoucherTabConstants.voucherDueDateTypes.map((dueDateTypes, index) => {
                                                        return <option value={dueDateTypes.value}>{dueDateTypes.label}</option>
                                                    })
                                                }
                                            </select>

                                        </Col>
                                    </Row>
                                    <Row className="marB15">
                                        <label className="text-left control-label div-flex col-sm-3">
                                            {VoucherTabConstants.AddVoucher.DueDate}
                                        </label>
                                        <Col sm={9} className={'zindexzero'}>
                                            <CustomDatePicker
                                                value={this.state.voucher.dueDate ? (new Date(this.state.voucher.dueDate).getFullYear() == 1 ? undefined : this.state.voucher.dueDate) : this.state.voucher.dueDate}
                                                onChange={this.handleDueDateChange} />

                                        </Col>
                                    </Row>
                                    <Row className="marB15">
                                        <label className="text-left control-label div-flex col-sm-3">
                                            {VoucherTabConstants.AddVoucher.VoucherAmount}
                                        </label>
                                        <Col sm={9} className="zeroborderRadius">
                                            <CurrencyTextBox
                                                value={this.state.amount}
                                                onChange={this.handleVoucherAmountChange}
                                                onBlur={this.handleVoucherAmountBlur}
                                                decimalPlaces={0} />
                                        </Col>
                                    </Row>

                                    <div>
                                        <Row className="marB15">
                                            <label className="text-left control-label div-flex col-sm-3">
                                                {VoucherTabConstants.AddVoucher.FormName}
                                            </label>
                                            <Col sm={9}>
                                                <AutoSuggestTextbox id={"voucherFormName"}
                                                    options={this.state.voucher.paymentType == VoucherTypes.PaymentVoucher ? this.state.paymentFormNames : this.state.estimatedFormNames}
                                                    onChange={(event) => this.onFormNameChangeDropdown(event)}
                                                    selectedValue={this.state.voucher.formName}
                                                    formMenuClass="formNameList"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="marB15">
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <select className="form-control"
                                                    name="dd-payment-methods"
                                                    value={this.state.voucher.customType}
                                                    onChange={this.handlePaymentMethodChange}>
                                                    {VoucherTabConstants.AddVoucher.paymentMethods.map((method, index) => {
                                                        return <option key={index} value={method.value}>{method.label}</option>
                                                    })}
                                                </select>
                                            </Col>
                                        </Row>
                                    </div>

                                    {this.state.voucher.customType == CustomType.AddCustom ?
                                        <Row className="marB15">
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <textarea style={{ height: '100px' }} className="form-control" name="customText" maxLength={50}
                                                    onChange={this.handleCustomTextChange} value={this.state.voucher.customText} />
                                            </Col>
                                        </Row> : null}

                                    {this.state.voucher.customType == CustomType.OnlinePaymentRequired ? this.state.isPaymentUrlSame ?
                                        <Row className="marB15">
                                            <label className="text-left control-label div-flex col-sx-6 col-sm-4 col-md-3 col-lg-3">
                                                {VoucherTabConstants.AddVoucher.PaymentUrl}
                                            </label>
                                            <Col xs={6} sm={8} md={9} lg={9}>
                                                <FormControl type="url" value={this.state.voucher.onlinePaymentUri} name="paymentUrl"
                                                    onChange={this.handlePaymentUrlChange} />
                                            </Col>
                                        </Row> :
                                        <div>
                                            <Row className="marB15">
                                                <Col className="text-left" xs={6} sm={4} md={3} lg={3} as={FormLabel}>
                                                    <RadioButtonComponent text=""
                                                        id="rdbMasterPaymentUrl"
                                                        checked={this.state.isMasterUrl}
                                                        value={1}
                                                        onChange={this.onPaymentUrlChangeRadio}
                                                    />
                                                </Col>
                                                <Col xs={6} sm={8} md={9} lg={9}>
                                                    <span className="span-control overflowText" title={this.state.masterPaymentUrl}>  {this.state.masterPaymentUrl}</span>
                                                </Col>
                                            </Row>
                                            <Row className="marB15">
                                                <Col className="text-left" xs={6} sm={4} md={3} lg={3} as={FormLabel}>
                                                    <RadioButtonComponent text=""
                                                        id="rdbEditedPaymentUrl"
                                                        checked={!this.state.isMasterUrl}
                                                        value={2}
                                                        onChange={this.onPaymentUrlChangeRadio}
                                                    />
                                                </Col>
                                                <Col xs={6} sm={8} md={9} lg={9}>
                                                    {this.state.isMasterUrl ?
                                                        <span className="span-control overflowText" title={this.state.voucher.onlinePaymentUri}> {this.state.voucher.onlinePaymentUri} </span> :
                                                        <FormControl type="url" value={this.state.voucher.onlinePaymentUri} name="editedPaymentUrl" onChange={this.handlePaymentUrlChange} />}
                                                </Col>
                                            </Row> </div> : null}
                                </FormGroup>
                            </Card.Body>
                            <Card.Footer className="clearfix">
                                <Row>
                                    <Col sm={6} md={6}>
                                        <Button type="button"
                                            className="pull-right"
                                            variant="light"
                                            onClick={this.onCancel}>
                                            <i className="fas fa-times"></i>
                                Cancel
                                    </Button>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <Button type="button"
                                            variant='info'
                                            disabled={!this.state.enableUpdate}
                                            onClick={this.handleUpdate}
                                        > <i className='fa fa-check'></i>Update
                                    </Button>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </div>
                    </Accordion.Collapse>

                </Card>
            </Accordion>
        </div>)
    }
}
